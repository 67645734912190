import useUploadUrl from "./useUploadUrl"

const useUpload = () => {
  const uploadUrl = useUploadUrl()
  return async (blob: Blob) => {
    const [url, body] = await Promise.all([uploadUrl(), blob.arrayBuffer()])
    await fetch(url.url, {
      method: 'PUT',
      body,
      headers: {
        'Content-Type': 'image/png'
      }
    })
    return url.key
  }
}

export default useUpload