import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, Fade, Paper, Slider, styled, TextField, Tooltip, Typography, TypographyProps } from '@mui/material'
import { MessageIds } from 'components/IntlProvider'
import useDebounce from 'hooks/useDebounce'
import { stable_diffusion } from 'proto'
import { createElement as $, FC, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { HistoryContext, InpaintingContext, ToolContext } from '..'
import Artboard from './Artboard'

type ImgToImgFoward = Omit<Required<{ 
  [K in keyof stable_diffusion.IImgToImgForward]: NonNullable<stable_diffusion.IImgToImgForward[K]>
}>, 'initImage'>

export type GeneratorInput = Omit<ImgToImgFoward, 'jobId' | 'debugUseTestImage'> & { imageId?: string, maskId?: string }

const ToolPure: FC<GeneratorInput> = ({
  imageId,
  strength,
  guidanceScale,
  numInferenceSteps,
  numImagesPerPrompt,
  prompt,
  negativePrompt
}) =>
  $(Box, { width: 512, flexShrink: 0 },
    $(Fade, { in: true, children: 
      $(Paper, { sx: { overflow: 'hidden' } }, 
        $(ToolArtboard, { imageId }),
        $(Prompts, { prompt, negativePrompt }),
        $(Settings, {
          strength,
          guidanceScale,
          numInferenceSteps,
          numImagesPerPrompt,
        }))
    }))

const ToolArtboard: FC<Pick<GeneratorInput, 'imageId' | 'maskId'>> = ({
  imageId,
  maskId
}) => {
  const [color, setColor] = useState("#993366")
  const [strokeWidth, setStrokeWidth] = useState(40)
  const onChange = useContext(ToolContext)
  const { history } = useContext(HistoryContext)
  const inpainting = useContext(InpaintingContext)
  const bag = {
    color,
    strokeWidth,
    history
  }

  return $(Box, null,
    inpainting.value
      ? $(Box, { sx: { background: `url(${imageId})` } },
          $(Artboard, {
            onChange: (maskId) => onChange({
              maskId,
              imageId: imageId?.replace('https://images.app.quecca.art/', '')
            }),
            value: maskId,
            clear: true,
            ...bag
          }))
      : $(Box, null,
          $(Artboard, {
            onChange: (imageId) => onChange({ imageId }),
            value: imageId,
            clear: false,
            ...bag
          })),
    $(Box, { padding: '1rem', paddingRight: '.5rem', display: 'flex' }, 
      $('input', {
        type: 'color',
        value: color,
        onChange: (event) => setColor(event.currentTarget.value)
      }),
      $(Slider, {
        sx: { marginLeft: '1rem', marginRight: '.5rem' },
        value: strokeWidth,
        size: 'small',
        valueLabelDisplay: 'auto',
        onChange: (event, value) => setStrokeWidth(value as number),
        min: 1,
        max: 100,
      })))
}

const Prompts: FC<Pick<GeneratorInput, 'prompt' | 'negativePrompt'>> = (props) => {
  const onChange = useContext(ToolContext)
  const [prompt, setPrompt] = useState(props.prompt.message)
  const [negativePrompt, setNegativePrompt] = useState(props.negativePrompt.message)

  const debouncedPrompt = useDebounce(prompt, 500)
  const debouncedNegativePrompt = useDebounce(negativePrompt, 500)

  const hasRendered = useRef(false)
  useEffect(() => {
    if (hasRendered.current)
      onChange({
        prompt: { message: debouncedPrompt },
        negativePrompt: { message: debouncedNegativePrompt },
      })
    else hasRendered.current = true
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[debouncedPrompt, debouncedNegativePrompt, hasRendered])

  return $(Box, { sx: { padding: '1rem' }},
    $(TextField, {
      label: 'Prompt',
      fullWidth: true,
      value: prompt,
      multiline: true,
      onChange: (event) => setPrompt(event.target.value)
    }),
    $(Box, { height: '1rem' }),
    $(TextField, {
      label: 'Negative prompt',
      fullWidth: true,
      value: negativePrompt,
      onChange: (event) => setNegativePrompt(event.target.value)
    }))
}

const InfoTooltip: FC<{ id: MessageIds }> = ({ id }) => 
  $(Tooltip, {
    title: $(FormattedMessage, { id }),
    children: $(InfoIcon, { fontSize: 'small', color: 'disabled' })
  })

const Settings: FC<Pick<GeneratorInput, 'numImagesPerPrompt' | 'numInferenceSteps' | 'strength' | 'guidanceScale'>> = ({
  numImagesPerPrompt,
  numInferenceSteps,
  strength,
  guidanceScale
}) => {
  const onChange = useContext(ToolContext)
  return $(Box, { sx: { padding: '1rem' }},
    $(LabelHolder, null, 
      $(Typography, labelStyles, 'Similar to image'),
      $(InfoTooltip, { id: 'tool.help.similarity' }),
      $(Typography, labelStyles, 'Similar to prompt')),
    $(Slider, {
      defaultValue: strength * 100,
      step: 1,
      onChangeCommitted: (event, value) => onChange({ strength: value as number * .01 }),
      min: 0,
      track: false,
      max: 100,
      valueLabelDisplay: 'auto',
      size: 'small'
    }),
    $(LabelHolder, null,
      $(Typography, labelStyles, $(FormattedMessage, { id: 'tool.inferenceSteps' })),
      $(InfoTooltip, { id: 'tool.help.inferenceSteps'})),
    $(Slider, {
      defaultValue: numInferenceSteps,
      step: 1,
      onChangeCommitted: (event, value) => onChange({ numInferenceSteps: value as number }),
      min: 1,
      max: 100,
      valueLabelDisplay: 'auto',
      size: 'small',
    }),
    $(LabelHolder, null, 
      $(Typography, labelStyles, $(FormattedMessage, { id: 'tool.guidance' })),
        $(InfoTooltip, { id: 'tool.help.guidance'})),
    $(Slider, {
      defaultValue: guidanceScale,
      step: 1,
      onChangeCommitted: (event, value) => onChange({ guidanceScale: value as number }),
      min: 1,
      max: 20,
      valueLabelDisplay: 'auto',
      size: 'small',
    }),
    $(LabelHolder, null, 
      $(Typography, labelStyles, $(FormattedMessage, { id: 'tool.variantCount' })),
        $(InfoTooltip, { id: 'tool.help.variantCount'})),
    $(Slider, {
      defaultValue: numImagesPerPrompt,
      step: 1,
      onChangeCommitted: (event, value) => onChange({ numImagesPerPrompt: value as number }),
      min: 1,
      max: 10,
      marks: true,
      valueLabelDisplay: 'auto',
      size: 'small',
    }))
}

const labelStyles: TypographyProps = {
  variant: 'caption',
  color: 'text.secondary'
}

const LabelHolder = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '&>*': {
    display: 'block'
  }
})

export default ToolPure