import { ButtonBase, Fade, Paper, styled } from '@mui/material'
import { createElement as $, FC, PropsWithChildren } from 'react'

const Card: FC<PropsWithChildren<{
  onClick: () => void
}>> = ({
  onClick,
  children
}) =>
  $(Fade, { in: true, children:
    $(ContainerPaper, null,
      $(Button, { onClick },
        children))})

const ContainerPaper = styled(Paper)({
  overflow: 'hidden'
})

const Button = styled(ButtonBase)({
  width: '100%',
  height: '100%'
})

export default Card