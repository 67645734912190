import { Box, Paper, Typography } from '@mui/material'
import { createElement as $, FC } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { TechinicalSubscription } from 'queries'

const Technical: FC<TechinicalSubscription['technical'][number]> = ({
  expiresAt
}) => {
  return $(Box, {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '15vh',
    },
    $(Paper, { sx: { padding: '1rem', textAlign: 'center' }},
      $(Typography, { variant: 'h6'}, 'Self-Optimizing in progress...'),
      $(Box, null, '🦾'),
      $(Typography, { variant: 'caption', color: 'text.secondary' },
        `Please come back on `,
        $(FormattedDate, { value: new Date(expiresAt) }),
        ' at ',
        $(FormattedTime, { value: new Date(expiresAt) }))))
}

export default Technical