import useUpload from 'hooks/useUpload'
import { createElement as $, FC, useCallback, useLayoutEffect, useState } from 'react'
import { Artboard as ReactArtboard, ArtboardRef, History, useBrush } from 'react-artboard'

const Artboard: FC<{
  onChange: (value: string) => void
  value?: string
  color: string
  strokeWidth: number
  history: History
  clear: boolean
}> = ({
  onChange,
  value,
  color,
  strokeWidth,
  history,
  clear
}) => {
  const [height] = useState(512)
  const [width] = useState(512)
  const tool = useBrush({ color, strokeWidth })
  const [ref, setRef] = useState<ArtboardRef | null>(null);
  const context = ref?.context
  const upload = useUpload()

  useLayoutEffect(() => {
    if (context && clear)
      context.clearRect(0, 0, height, width) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context])

  useLayoutEffect(() => {
    if (context && value) {
      toDataURL(value)
        .then((src) => {
          const image = new Image()
          image.src = src
          image.onload = () => context?.drawImage(image,0,0)
        })
    }
  }, [context, value, height, width])

  const onEndStroke = useCallback(async () => {
    if (context?.canvas) {
      context.canvas.toBlob((blob)=> {
        if (blob)
          upload(blob).then(onChange)
      }, 'png', 1)
    }
  }, [context?.canvas, upload, onChange])

  return $(ReactArtboard, {
    id: 'canvas',
    ref: setRef,
    tool,
    height,
    width,
    history,
    onEndStroke,
    style: {
      display: 'block'
    }
  })
}

const toDataURL = (url: string) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export default Artboard