import { ApolloProvider } from '@apollo/client/react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createElement as $, FC, ReactElement, useMemo } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import useClient from 'apolloClient'
import Confirm from 'pages/Confirm'
import Login from 'pages/Login'
import Project from 'pages/Project'
import Projects from 'pages/Projects'
import Technical from 'pages/Technical'
import { useMeQuery, useSessionSubscription, useTechinicalSubscription } from 'queries'
import IntlProvider from 'components/IntlProvider'

const App = () => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
      },
    }),
  [prefersDarkMode])

  const client = useClient(process.env.REACT_APP_API_URL!, TOKEN_KEY)

  return $(ThemeProvider, { theme },
    $(CssBaseline, { enableColorScheme: true }),
    $(IntlProvider, null,
      $(ApolloProvider, {
        client,
        children: $(BrowserRouter, null,
          $(WithAuth, {
            authenticatedRoutes,
            anonymousRoutes
          }))
    })))
}

export const TOKEN_KEY = 'auth:token'

const WithAuth: FC<{
  anonymousRoutes: ReactElement
  authenticatedRoutes: ReactElement
}> = ({
  anonymousRoutes,
  authenticatedRoutes
}) => {
  const session = useSessionSubscription()
  const techincal = useTechinicalSubscription()
  const me = useMeQuery()
  if (process.env.NODE_ENV !== 'development' && techincal.data?.technical.length)
    return $(Technical, techincal.data?.technical[0])
  if (!session.data?.currentSession) return $('div')
  localStorage.setItem(TOKEN_KEY, session.data.currentSession.token)
  if (me.data?.me) return authenticatedRoutes
  return anonymousRoutes
}

const tokenConfirmationRoute = $(Route, { path: '/authenticate/:token', element: $(Confirm)})

const anonymousRoutes = $(Routes, null,
  tokenConfirmationRoute,
  $(Route, { path: '*', element: $(Login) }))

const authenticatedRoutes = $(Routes, null,
  $(Route, { path: '/projects/:id', element: $(Project) }),
  $(Route, { path: '/projects', element: $(Projects) }),
  tokenConfirmationRoute,
  $(Route, { path: '*', element: $(Projects) }))

export default App