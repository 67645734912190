import { Box, styled } from '@mui/material'
import set from 'lodash/fp/set'
import { createContext, createElement as $, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-artboard'
import { useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { AddGenerationRequestMutationVariables, GeneratedImagesDocument, GeneratedImagesSubscription, ImageConstraint, ImageUpdateColumn, ProjectDocument, ProjectQuery, useAddGenerationRequestMutation, useProjectQuery } from 'queries'
import Tool, { GeneratorInput } from './Tool'
import Bar from './Bar'
import Content from './Content'
import Info from './Info'

const Project = () => {
  const { id } = useParams<'id'>()

  const { data, subscribeToMore, fetchMore } = useProjectQuery({ variables: { id }})
  const batchSize = 2

  useEffect(() =>
    subscribeToMore({
      document: GeneratedImagesDocument,
      variables: { projectId: id, from: data?.generatedImages[0]?.createdAt || new Date(), batchSize },
      updateQuery: (previousData, subscriptionResult) => {
        const nextData = subscriptionResult.subscriptionData.data as unknown as GeneratedImagesSubscription
        return {
          __typename: 'query_root',
          project: previousData.project,
          generatedImageAggregate: {
            __typename: 'GeneratedImageAggregate',
            aggregate: {
              __typename: 'GeneratedImageAggregateFields',
              count: (previousData.generatedImageAggregate.aggregate?.count || 0) + batchSize
            }
          },
          generatedImages: [
            ...nextData.generatedImageStream.reverse(),
            ...previousData.generatedImages
          ]
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [])

  if (!data)
    return $('div')

  return $(ProjectPure, { ...data, fetchMore })
}

const ProjectPure: FC<ProjectQuery & WithFetchMore> = ({
  project,
  generatedImages,
  generatedImageAggregate,
  fetchMore
}) => {
  const history = useHistory(10)
  const [mutate] = useAddGenerationRequestMutation({ ignoreResults: true })
  const projectId = project!.id
  const request = project?.latestGenerationRequest[0]
  const [inpainting, setInpainting] = useState(!!request?.maskId)

  const baseState: GeneratorInput = useMemo(() => ({
    ...defaultInput,
    ...request && {
      ...request.input,
      imageId: request.image?.id,
      maskId: request.maskId
    }
  }), [request])

  const onChange = useCallback(async (rawInput: Partial<GeneratorInput>) => {
    const { imageId, maskId, ...input } = { ...baseState, ...rawInput }
    const id = imageId || v4() // FIXME this is a poor's man fix for lack of image
    const variables: AddGenerationRequestMutationVariables = {
      data: {
        projectId,
        input,
        ...maskId && inpainting && {
          mask: {
            onConflict: {
              constraint: ImageConstraint.ImagePkey,
              update_columns: [ImageUpdateColumn.UpdatedAt]
            },
            data: {
              id: maskId
            }
          }
        },
        image: {
          onConflict: {
            constraint: ImageConstraint.ImagePkey,
            update_columns: [ImageUpdateColumn.UpdatedAt]
          },
          data: {
            id
          }
        }
      }
    }
    await mutate({
      variables,
      optimisticResponse: {
        __typename: 'mutation_root',
        insertGenerationRequestsOne: {
          __typename: 'generationRequests',
          id: 'new'
        }
      },
      update: (cache) => {
        const query = { query: ProjectDocument, variables: { id: projectId }}
        const cachedData = cache.readQuery(query) as ProjectQuery
        const data = set('project.latestGenerationRequest', [{
          input,
          image: {
            id,
            url: `https://images.app.quecca.art/${id}`
          },
          maskId
        }], cachedData)
        cache.writeQuery({ ...query, data })
      }
    })
  }, [projectId, baseState, mutate, inpainting])

  const state: GeneratorInput = {
    ...baseState,
    ...request && {
      imageId: request.image?.url!,
    }
  }

  return $(ToolContext.Provider, { value: onChange },
    $(HistoryContext.Provider, { value: history },
      $(InpaintingContext.Provider, { value: { onChange: setInpainting, value: inpainting } },
        $(Bar),
        $(Container, null,
          $(Tool, state),
          generatedImages.length === 0
            ? $(Info)
            : $(Content, { generatedImages, generatedImageAggregate, fetchMore })))))
}

export const ToolContext = createContext<(input: Partial<GeneratorInput>) => void>(console.log)
// @ts-ignore
export const HistoryContext = createContext<ReturnType<typeof useHistory>>({})
export const InpaintingContext = createContext<{
  value: boolean,
  onChange: (value: boolean) => void
}>({
  onChange: console.log,
  value: false
})

const Container = styled(Box)({
  display: 'flex',
  padding: '1rem',
  paddingTop: '5rem'
})

const defaultInput: GeneratorInput = {
  strength: .99,
  numImagesPerPrompt: 2,
  numInferenceSteps: 50,
  prompt: { message: ' ' },
  negativePrompt: { message: 'low quality' },
  guidanceScale: 7.5,
  eta: 0
}

export type WithFetchMore = { fetchMore: ReturnType<typeof useProjectQuery>['fetchMore'] }

export default Project