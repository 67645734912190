import {ListItem, Skeleton, useTheme, useMediaQuery, Box } from '@mui/material'
import { createElement as $, FC, Fragment, ReactElement } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'

const DynamicScrollSensor: FC<DynamicScrollSensorProps> = ({
  count,
  length,
  loading,
  fetchMore,
  loadingElement
}) => {
  const loadingBit = loadingElement
    ? $(Fragment, null, new Array((count - length < 10 && count - length) || 10).fill('').map(loadingElement))
    : progress
  return !length
    ? null
    : count === length
      ? null
      : loading
        ? loadingBit
        : $(ReactVisibilitySensor, {
            onChange: (isVisible: boolean) => isVisible && fetchMore({ variables: { offset: length } })
            },
            loadingBit)
}

const LoadingItemIteratee = (value: any, index: number) => 
  $(LoadingElement, { key: index })

const LoadingElement = (value: any, index: number) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return $(ListItem, null,
    $(Box, !isMobile ? { display: 'flex' } : null,
      $(Box, isMobile ? null : { width: '9rem', height: '3rem' },
          $(Skeleton, { width: '15ex' })),
      $(Box, null,
          $(Skeleton, { width: '13ex' } ),
          $(Skeleton, isMobile ? { width: '35ex' } : { width: '60ex' } ))))
}

const loadingList = new Array(20).fill('').map(LoadingItemIteratee)
  
const progress = $(Box, null, loadingList)

type DynamicScrollSensorProps = {
  count: number
  length: number
  loading: boolean
  fetchMore: (props: FetchMoreProps) => any 
  loadingElement?: (value: any, index: number) => ReactElement<any, any>
}

type FetchMoreProps = { variables: { offset: number }}

export default DynamicScrollSensor
