import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import UploadIcon from '@mui/icons-material/Upload'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import { AppBar, Box, FormControlLabel, IconButton, IconButtonProps, Switch, Toolbar, Tooltip } from '@mui/material'
import { createElement as $, useContext, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { HistoryContext, InpaintingContext, ToolContext } from '.'
import useUpload from 'hooks/useUpload'

const Bar = () => {
  const navigate = useNavigate()
  return $(AppBar, null,
    $(Toolbar, null,
      $(IconButton, { 
        edge: 'start',
        onClick: () => navigate('/')
        },
        $(ArrowBackIcon)),
      $(Box, { flex: 1 }),
      $(Inpainting),
      $(Share),
      $(Upload),
      $(Undo)))
}

const Share = () => {
  const children = $(Box, null,
    $(IconButton, { disabled: true },
      $(PersonAddIcon)))
  return $(Tooltip, {
    title: 'Coming soon',
    children
  })
}

const Undo = () => {

  const { canUndo, canRedo, undo, redo } = useContext(HistoryContext)

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (canUndo && isUndo(e)) undo()
      if (canRedo && isRedo(e)) redo() // FIXME does not work
    }
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler)
  }, [canUndo, canRedo, undo, redo])

  const undoChildren = $(Box, null,
    $(IconButton, { onClick: undo, disabled: !canUndo },
      $(UndoIcon)))
  const redoChildren = $(Box, null, 
    $(IconButton, { onClick: redo, edge: 'end', disabled: !canRedo },
      $(RedoIcon)))
  return $(Fragment, null,
    $(Tooltip, {
      title: 'Undo (Ctrl/Cmd+Z)',
      children: undoChildren
    }),
    $(Tooltip, {
      title: 'Redo (Ctrl/Cmd+Y)',
      children: redoChildren
    }))
}

const isUndo = (e: KeyboardEvent): boolean =>
  (e.ctrlKey || e.metaKey) && e.key === 'z'

const isRedo = (e: KeyboardEvent): boolean =>
  (e.ctrlKey || e.metaKey) && (e.key === 'y' || (e.key === 'z' && e.shiftKey)) // FIXME shift variant is not working

const Inpainting = () => {
  const { onChange, value } = useContext(InpaintingContext)
  const children = $(FormControlLabel, {
    control: $(Switch, {
      disabled: false,
      checked: value,
      onChange: (e, value) => onChange(value)
    }),
    label: 'Inpainting'
  })
  return $(Tooltip, {
    title: 'Coming soon',
    children
  })
}

const Upload = () => {
  const upload = useUpload()
  const onChange = useContext(ToolContext)
  const children = $<IconButtonProps<'label', { component: 'label' }>>(IconButton, { component: 'label' },
    $('input', {
      hidden: true,
      accept:'image/*',
      type: 'file',
      onChange: async (e) => {
        const file = e.target.files?.[0]
        if (file) {
          const imageId = await upload(file)
          onChange({ imageId })
        }
      }
      }),
    $(UploadIcon))
  return $(Tooltip, { title: 'Upload image', children })
}

export default Bar