import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { createElement as $ } from 'react'
import { useIntl } from 'react-intl'

const Info = () => {
  const intl = useIntl()
  return $(InfoContainer, null,
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.prompt' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.prompt' })),
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.prompt.negative' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.prompt.negative' })),
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.similarity.image' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.similarity' })),
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.inferenceSteps' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.inferenceSteps' })),
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.guidance' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.guidance' })),
    $(Typography, { variant: 'h6', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.variantCount' })),
    $(Typography, { variant: 'body2', color: 'text.secondary' }, intl.formatMessage({ id: 'tool.help.variantCount' })))
}

const InfoContainer = styled(Box)({
  padding: '1rem',
  maxWidth: '60ex',
  marginLeft: '2rem',
  '&>p': {
    marginBottom: '1rem'
  }
})

export default Info