import { useCallback, useEffect, useRef } from 'react'
import { useGetUploadUrlMutation } from 'queries'

const useUploadUrl = () => {

  const [mutate] = useGetUploadUrlMutation({ ignoreResults: true })
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const value = useRef<Promise<{ key: string, url: string }>>()

  const getUploadUrl = useCallback(async () => {
    clearTimeout(timeout.current)
    const nextValue = mutate()
      .then(({ data }) => {
        const refreshInterval = (data?.getUploadUrl.expiresIn || 60) * 1000
        timeout.current = setTimeout(getUploadUrl, refreshInterval)
        const result = {
          key: data!.getUploadUrl.key,
          url: data!.getUploadUrl.url
        }
        value.current = Promise.resolve(result)
        return result
      })
    return value.current || nextValue
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getUploadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return getUploadUrl
}

export default useUploadUrl