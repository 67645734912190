import AddIcon from '@mui/icons-material/AddCircleOutline'
import { Box, styled, Typography } from '@mui/material'
import { createElement as $, FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Logout from 'components/Logout'
import { ProjectsSubscription, useAddProjectMutation, useMeQuery, useProjectsSubscription } from 'queries'
import Card from './Card'

const Projects: FC = () => {
  const { data } = useProjectsSubscription()
  return $(Container, null,
    $(Content, null,
      $(Add),
      data?.projects.map(ProjectIteratee)),
    $(Footer))
}

type ProjectListItemType = ProjectsSubscription['projects'][number]

const ProjectIteratee = (item: ProjectListItemType) =>
  $(ProjectListItem, { key: item.id, ...item })

const ProjectListItem: FC<ProjectListItemType> = ({
  id,
  name,
  generationRequests
}) => {
  const navigate = useNavigate()
  const src = generationRequests[0]?.generatedImages[0]?.image.url!
  return $(Card, {
    onClick: () => navigate(`/projects/${id}`)}, 
    src
      ? $(ProjectListItemImage, { src })
      : name || id)
}

const ProjectListItemImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block'
})

const Add = () => {
  const [mutate] = useAddProjectMutation({
    variables: { data: { name: 'new project' }}
  })
  return $(Card, { onClick: mutate },
    $(Box, {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem'
      },
      $(AddIcon),
      $(Typography, { variant: 'button', marginTop: '1px', marginLeft: '.5rem' }, 'New Project')))
}

const Container = styled(Box)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column'
})

const Content = styled(Box)({
  flexGrow: 1,
  display: 'grid',
  overflowY: 'auto',
  padding: '1rem',
  columnGap: '1rem',
  rowGap: '1rem',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridAutoRows: '8rem',
}, ({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  }
}))

const Footer: FC = () => {
  const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
  return $(FooterContainer, null,
    data?.me?.email,
    $(Box, { width: '1rem' }),
    $(Logout))
}

const FooterContainer = styled(Box)({
  display: 'flex',
  borderTop: '1px solid black',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '.5rem'
}, ({ theme }) => ({
  borderTopColor: theme.palette.divider
}))

export default Projects