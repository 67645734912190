import { ApolloError } from '@apollo/client'
import { Paper, Skeleton, styled, Typography } from '@mui/material'
import { createElement as $, FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLoginMutation } from 'queries'

const Confirm: FC = () => {
  const { token } = useParams<'token'>()
  const [mutate, { data, loading, error }] = useLoginMutation({ variables: { token }})

  useEffect(() => { mutate().catch(console.log) }, [mutate])

  useEffect(() => {
    if (data)
      setTimeout(() => {
        window.location.href = '/'
      }, 500)
  }, [data])

  return $(Container, null,
    $(Typography, null,
      loading
        ? $(Skeleton, { width: '24ex' })
        : error
          ? 'Email confirmation failed'
          : 'Email confirmed, redirecting'),
    $(Typography, { align: 'center', variant: 'caption', color: 'text.secondary' },
      loading
        ? $(Skeleton, { width: '46ex' })
        : error
          ? getErrorMessage(error)
          :  '❤️'))
}

const getErrorMessage = (error: ApolloError) => {
  const invalidKey = error?.graphQLErrors[0].extensions.code === 'constraint-violation'
  if (invalidKey)
    return 'Token invalid or expired'
  return error.message
}

const Container = styled(Paper)({
  margin: 'auto',
  marginTop: '10vh',
  padding: '1rem',
  maxWidth: '60ex',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})

export default Confirm