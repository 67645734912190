import DownloadIcon from '@mui/icons-material/Download'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Fade, IconButton, styled, Typography } from '@mui/material'
import { createElement as $, FC, useContext, useState } from 'react'
import { ToolContext } from '.'
import { ResultImageFragment } from 'queries'

const ResultImageIteratee = (image: GeneratedImage) =>
  $(Fade, {
    in: true,
    key: image.id,
    children: $(Box, null,
      $(ResultImage, { key: image.id, ...image }))
  })

const ResultImage: FC<GeneratedImage> = ({
  image
}) => {
  const src = image.url!
  const [label, setLabel] = useState('')
  const onChange = useContext(ToolContext)
  return $(ResultContainer, null,
    $(ImageContainer, { src }),
    $(Cover, null,
      $(IconsContainer, null,
        $(Box, null,
          $(Icon, {
            icon: FlipCameraAndroidIcon,
            label: 'Use on canvas',
            setLabel,
            onClick: () => onChange({ imageId: image.id })
          }),
          $(Icon, {
            icon: DownloadIcon,
            label: 'Download image',
            setLabel,
            href: src
          }),
          $(Icon, {
            icon: InfoIcon,
            label: 'View prompt and meta',
            setLabel,
            onClick: () => alert('not implemented')
          })),
          $(Fade, { in: !!label, children: $(Typography, null, label || '\u00A0') }))))
}

const Icon: FC<{
  icon: typeof FlipCameraAndroidIcon,
  label: string,
  setLabel: (label: string) => void,
  href?: string,
  onClick?: () => void
}> = ({
  icon,
  label,
  setLabel,
  href,
  onClick
}) =>
  $(IconButton, {
    ...href && {
      href,
      component: 'a',
      download: 'kek.jpg',
      title: 'generated image',
      target: 'blank'
    },
    onClick,
    onMouseEnter: () => setLabel(label),
    onMouseLeave: () => setLabel('')
    },
    $(icon))


const ResultContainer = styled(Box)({
  position: 'relative'
})

const Cover = styled(Box)({
  // @ts-ignore
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: 'absolute',
  transition: 'opacity, background, backdrop-filter',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  ':hover': {
    opacity: 1,
    backdropFilter: 'blur(4px)',
  }
}, ({ theme }) => ({
  transitionDuration: `${theme.transitions.duration.shortest}ms`,
  transitionTimingFunction: theme.transitions.easing.sharp,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: hexToRGB(theme.palette.background.default, .4)
}))

const IconsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: '2rem'
})

const ImageContainer = styled('img')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'block',
  width: '100%'
}))

function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}

type GeneratedImage = ResultImageFragment

export default ResultImageIteratee