import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "auth.active_session" */
export type AuthActiveSession = {
  __typename: 'AuthActiveSession';
  /** An object relationship */
  confirmation: Maybe<AuthConfirmedSession>;
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  token: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "auth.active_session" */
export type AuthActiveSessionAggregate = {
  __typename: 'AuthActiveSessionAggregate';
  aggregate: Maybe<AuthActiveSessionAggregateFields>;
  nodes: Array<AuthActiveSession>;
};

/** aggregate fields of "auth.active_session" */
export type AuthActiveSessionAggregateFields = {
  __typename: 'AuthActiveSessionAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthActiveSessionMaxFields>;
  min: Maybe<AuthActiveSessionMinFields>;
};


/** aggregate fields of "auth.active_session" */
export type AuthActiveSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.active_session". All fields are combined with a logical 'AND'. */
export type AuthActiveSessionBoolExp = {
  _and?: InputMaybe<Array<AuthActiveSessionBoolExp>>;
  _not?: InputMaybe<AuthActiveSessionBoolExp>;
  _or?: InputMaybe<Array<AuthActiveSessionBoolExp>>;
  confirmation?: InputMaybe<AuthConfirmedSessionBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  token?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type AuthActiveSessionMaxFields = {
  __typename: 'AuthActiveSessionMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  token: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthActiveSessionMinFields = {
  __typename: 'AuthActiveSessionMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  token: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "auth.active_session". */
export type AuthActiveSessionOrderBy = {
  confirmation?: InputMaybe<AuthConfirmedSessionOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
};

/** select columns of table "auth.active_session" */
export enum AuthActiveSessionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token'
}

/** Streaming cursor of the table "auth_active_session" */
export type AuthActiveSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthActiveSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthActiveSessionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "auth.confirmed_session" */
export type AuthConfirmedSession = {
  __typename: 'AuthConfirmedSession';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  /** An object relationship */
  logout: Maybe<AuthLogout>;
  /** An object relationship */
  user: Maybe<Users>;
  userId: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "auth.confirmed_session" */
export type AuthConfirmedSessionAggregate = {
  __typename: 'AuthConfirmedSessionAggregate';
  aggregate: Maybe<AuthConfirmedSessionAggregateFields>;
  nodes: Array<AuthConfirmedSession>;
};

/** aggregate fields of "auth.confirmed_session" */
export type AuthConfirmedSessionAggregateFields = {
  __typename: 'AuthConfirmedSessionAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthConfirmedSessionMaxFields>;
  min: Maybe<AuthConfirmedSessionMinFields>;
};


/** aggregate fields of "auth.confirmed_session" */
export type AuthConfirmedSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.confirmed_session" */
export type AuthConfirmedSessionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Auth_Confirmed_Session_Max_Order_By>;
  min?: InputMaybe<Auth_Confirmed_Session_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.confirmed_session" */
export type AuthConfirmedSessionArrRelInsertInput = {
  data: Array<AuthConfirmedSessionInsertInput>;
};

/** Boolean expression to filter rows from the table "auth.confirmed_session". All fields are combined with a logical 'AND'. */
export type AuthConfirmedSessionBoolExp = {
  _and?: InputMaybe<Array<AuthConfirmedSessionBoolExp>>;
  _not?: InputMaybe<AuthConfirmedSessionBoolExp>;
  _or?: InputMaybe<Array<AuthConfirmedSessionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  logout?: InputMaybe<AuthLogoutBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** input type for inserting data into table "auth.confirmed_session" */
export type AuthConfirmedSessionInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  logout?: InputMaybe<AuthLogoutObjRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthConfirmedSessionMaxFields = {
  __typename: 'AuthConfirmedSessionMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  userId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthConfirmedSessionMinFields = {
  __typename: 'AuthConfirmedSessionMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  userId: Maybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "auth.confirmed_session" */
export type AuthConfirmedSessionObjRelInsertInput = {
  data: AuthConfirmedSessionInsertInput;
};

/** Ordering options when selecting data from "auth.confirmed_session". */
export type AuthConfirmedSessionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logout?: InputMaybe<AuthLogoutOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "auth.confirmed_session" */
export enum AuthConfirmedSessionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'userId'
}

/** Streaming cursor of the table "auth_confirmed_session" */
export type AuthConfirmedSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthConfirmedSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthConfirmedSessionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "auth.email_confirmation" */
export type AuthEmailConfirmation = {
  __typename: 'AuthEmailConfirmation';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

/** aggregated selection of "auth.email_confirmation" */
export type AuthEmailConfirmationAggregate = {
  __typename: 'AuthEmailConfirmationAggregate';
  aggregate: Maybe<AuthEmailConfirmationAggregateFields>;
  nodes: Array<AuthEmailConfirmation>;
};

/** aggregate fields of "auth.email_confirmation" */
export type AuthEmailConfirmationAggregateFields = {
  __typename: 'AuthEmailConfirmationAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthEmailConfirmationMaxFields>;
  min: Maybe<AuthEmailConfirmationMinFields>;
};


/** aggregate fields of "auth.email_confirmation" */
export type AuthEmailConfirmationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthEmailConfirmationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.email_confirmation". All fields are combined with a logical 'AND'. */
export type AuthEmailConfirmationBoolExp = {
  _and?: InputMaybe<Array<AuthEmailConfirmationBoolExp>>;
  _not?: InputMaybe<AuthEmailConfirmationBoolExp>;
  _or?: InputMaybe<Array<AuthEmailConfirmationBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.email_confirmation" */
export enum AuthEmailConfirmationConstraint {
  /** unique or primary key constraint on columns "id" */
  TokenConfirmationPkey = 'token_confirmation_pkey'
}

/** input type for inserting data into table "auth.email_confirmation" */
export type AuthEmailConfirmationInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthEmailConfirmationMaxFields = {
  __typename: 'AuthEmailConfirmationMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthEmailConfirmationMinFields = {
  __typename: 'AuthEmailConfirmationMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.email_confirmation" */
export type AuthEmailConfirmationMutationResponse = {
  __typename: 'AuthEmailConfirmationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthEmailConfirmation>;
};

/** on_conflict condition type for table "auth.email_confirmation" */
export type AuthEmailConfirmationOnConflict = {
  constraint: AuthEmailConfirmationConstraint;
  update_columns?: Array<AuthEmailConfirmationUpdateColumn>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};

/** Ordering options when selecting data from "auth.email_confirmation". */
export type AuthEmailConfirmationOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.email_confirmation */
export type AuthEmailConfirmationPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequest = {
  __typename: 'AuthEmailConfirmationRequest';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  sessionId: Scalars['uuid'];
};

/** aggregated selection of "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestAggregate = {
  __typename: 'AuthEmailConfirmationRequestAggregate';
  aggregate: Maybe<AuthEmailConfirmationRequestAggregateFields>;
  nodes: Array<AuthEmailConfirmationRequest>;
};

/** aggregate fields of "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestAggregateFields = {
  __typename: 'AuthEmailConfirmationRequestAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthEmailConfirmationRequestMaxFields>;
  min: Maybe<AuthEmailConfirmationRequestMinFields>;
};


/** aggregate fields of "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthEmailConfirmationRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.email_confirmation_request". All fields are combined with a logical 'AND'. */
export type AuthEmailConfirmationRequestBoolExp = {
  _and?: InputMaybe<Array<AuthEmailConfirmationRequestBoolExp>>;
  _not?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
  _or?: InputMaybe<Array<AuthEmailConfirmationRequestBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.email_confirmation_request" */
export enum AuthEmailConfirmationRequestConstraint {
  /** unique or primary key constraint on columns "id" */
  TokenRequestPkey = 'token_request_pkey'
}

/** input type for inserting data into table "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthEmailConfirmationRequestMaxFields = {
  __typename: 'AuthEmailConfirmationRequestMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  sessionId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthEmailConfirmationRequestMinFields = {
  __typename: 'AuthEmailConfirmationRequestMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  sessionId: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestMutationResponse = {
  __typename: 'AuthEmailConfirmationRequestMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthEmailConfirmationRequest>;
};

/** on_conflict condition type for table "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestOnConflict = {
  constraint: AuthEmailConfirmationRequestConstraint;
  update_columns?: Array<AuthEmailConfirmationRequestUpdateColumn>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};

/** Ordering options when selecting data from "auth.email_confirmation_request". */
export type AuthEmailConfirmationRequestOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sessionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.email_confirmation_request */
export type AuthEmailConfirmationRequestPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.email_confirmation_request" */
export enum AuthEmailConfirmationRequestSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "auth.email_confirmation_request" */
export type AuthEmailConfirmationRequestSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_email_confirmation_request" */
export type AuthEmailConfirmationRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthEmailConfirmationRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthEmailConfirmationRequestStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.email_confirmation_request" */
export enum AuthEmailConfirmationRequestUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

export type AuthEmailConfirmationRequestUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthEmailConfirmationRequestSetInput>;
  /** filter the rows which have to be updated */
  where: AuthEmailConfirmationRequestBoolExp;
};

/** select columns of table "auth.email_confirmation" */
export enum AuthEmailConfirmationSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.email_confirmation" */
export type AuthEmailConfirmationSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_email_confirmation" */
export type AuthEmailConfirmationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthEmailConfirmationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthEmailConfirmationStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.email_confirmation" */
export enum AuthEmailConfirmationUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

export type AuthEmailConfirmationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthEmailConfirmationSetInput>;
  /** filter the rows which have to be updated */
  where: AuthEmailConfirmationBoolExp;
};

/** columns and relationships of "auth.logout" */
export type AuthLogout = {
  __typename: 'AuthLogout';
  /** An object relationship */
  confirmedSession: Maybe<AuthConfirmedSession>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

/** aggregated selection of "auth.logout" */
export type AuthLogoutAggregate = {
  __typename: 'AuthLogoutAggregate';
  aggregate: Maybe<AuthLogoutAggregateFields>;
  nodes: Array<AuthLogout>;
};

/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFields = {
  __typename: 'AuthLogoutAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthLogoutMaxFields>;
  min: Maybe<AuthLogoutMinFields>;
};


/** aggregate fields of "auth.logout" */
export type AuthLogoutAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.logout". All fields are combined with a logical 'AND'. */
export type AuthLogoutBoolExp = {
  _and?: InputMaybe<Array<AuthLogoutBoolExp>>;
  _not?: InputMaybe<AuthLogoutBoolExp>;
  _or?: InputMaybe<Array<AuthLogoutBoolExp>>;
  confirmedSession?: InputMaybe<AuthConfirmedSessionBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.logout" */
export enum AuthLogoutConstraint {
  /** unique or primary key constraint on columns "id" */
  LogoutPkey = 'logout_pkey'
}

/** input type for inserting data into table "auth.logout" */
export type AuthLogoutInsertInput = {
  confirmedSession?: InputMaybe<AuthConfirmedSessionObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthLogoutMaxFields = {
  __typename: 'AuthLogoutMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthLogoutMinFields = {
  __typename: 'AuthLogoutMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.logout" */
export type AuthLogoutMutationResponse = {
  __typename: 'AuthLogoutMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthLogout>;
};

/** input type for inserting object relation for remote table "auth.logout" */
export type AuthLogoutObjRelInsertInput = {
  data: AuthLogoutInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};

/** on_conflict condition type for table "auth.logout" */
export type AuthLogoutOnConflict = {
  constraint: AuthLogoutConstraint;
  update_columns?: Array<AuthLogoutUpdateColumn>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};

/** Ordering options when selecting data from "auth.logout". */
export type AuthLogoutOrderBy = {
  confirmedSession?: InputMaybe<AuthConfirmedSessionOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.logout */
export type AuthLogoutPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.logout" */
export enum AuthLogoutSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.logout" */
export type AuthLogoutSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_logout" */
export type AuthLogoutStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthLogoutStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthLogoutStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.logout" */
export enum AuthLogoutUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

export type AuthLogoutUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthLogoutSetInput>;
  /** filter the rows which have to be updated */
  where: AuthLogoutBoolExp;
};

/** columns and relationships of "auth.token" */
export type AuthToken = {
  __typename: 'AuthToken';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sessionId: Scalars['uuid'];
};

/** aggregated selection of "auth.token" */
export type AuthTokenAggregate = {
  __typename: 'AuthTokenAggregate';
  aggregate: Maybe<AuthTokenAggregateFields>;
  nodes: Array<AuthToken>;
};

/** aggregate fields of "auth.token" */
export type AuthTokenAggregateFields = {
  __typename: 'AuthTokenAggregateFields';
  count: Scalars['Int'];
  max: Maybe<AuthTokenMaxFields>;
  min: Maybe<AuthTokenMinFields>;
};


/** aggregate fields of "auth.token" */
export type AuthTokenAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthTokenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.token". All fields are combined with a logical 'AND'. */
export type AuthTokenBoolExp = {
  _and?: InputMaybe<Array<AuthTokenBoolExp>>;
  _not?: InputMaybe<AuthTokenBoolExp>;
  _or?: InputMaybe<Array<AuthTokenBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  sessionId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "auth.token" */
export enum AuthTokenConstraint {
  /** unique or primary key constraint on columns "id" */
  TokenPkey1 = 'token_pkey1'
}

/** input type for inserting data into table "auth.token" */
export type AuthTokenInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AuthTokenMaxFields = {
  __typename: 'AuthTokenMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  sessionId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AuthTokenMinFields = {
  __typename: 'AuthTokenMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  sessionId: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.token" */
export type AuthTokenMutationResponse = {
  __typename: 'AuthTokenMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthToken>;
};

/** on_conflict condition type for table "auth.token" */
export type AuthTokenOnConflict = {
  constraint: AuthTokenConstraint;
  update_columns?: Array<AuthTokenUpdateColumn>;
  where?: InputMaybe<AuthTokenBoolExp>;
};

/** Ordering options when selecting data from "auth.token". */
export type AuthTokenOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  sessionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.token */
export type AuthTokenPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.token" */
export enum AuthTokenSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "auth.token" */
export type AuthTokenSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "auth_token" */
export type AuthTokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AuthTokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthTokenStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.token" */
export enum AuthTokenUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'sessionId'
}

export type AuthTokenUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthTokenSetInput>;
  /** filter the rows which have to be updated */
  where: AuthTokenBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "generated_image" */
export type GeneratedImage = {
  __typename: 'GeneratedImage';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  generationRequest: GenerationRequests;
  generationRequestId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  image: Image;
  imageId: Scalars['uuid'];
};

/** aggregated selection of "generated_image" */
export type GeneratedImageAggregate = {
  __typename: 'GeneratedImageAggregate';
  aggregate: Maybe<GeneratedImageAggregateFields>;
  nodes: Array<GeneratedImage>;
};

/** aggregate fields of "generated_image" */
export type GeneratedImageAggregateFields = {
  __typename: 'GeneratedImageAggregateFields';
  count: Scalars['Int'];
  max: Maybe<GeneratedImageMaxFields>;
  min: Maybe<GeneratedImageMinFields>;
};


/** aggregate fields of "generated_image" */
export type GeneratedImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "generated_image" */
export type GeneratedImageAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Generated_Image_Max_Order_By>;
  min?: InputMaybe<Generated_Image_Min_Order_By>;
};

/** input type for inserting array relation for remote table "generated_image" */
export type GeneratedImageArrRelInsertInput = {
  data: Array<GeneratedImageInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GeneratedImageOnConflict>;
};

/** Boolean expression to filter rows from the table "generated_image". All fields are combined with a logical 'AND'. */
export type GeneratedImageBoolExp = {
  _and?: InputMaybe<Array<GeneratedImageBoolExp>>;
  _not?: InputMaybe<GeneratedImageBoolExp>;
  _or?: InputMaybe<Array<GeneratedImageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  generationRequest?: InputMaybe<GenerationRequestsBoolExp>;
  generationRequestId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imageId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "generated_image" */
export enum GeneratedImageConstraint {
  /** unique or primary key constraint on columns "id" */
  GeneratedImagePkey = 'generated_image_pkey'
}

/** input type for inserting data into table "generated_image" */
export type GeneratedImageInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generationRequest?: InputMaybe<GenerationRequestsObjRelInsertInput>;
  generationRequestId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imageId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GeneratedImageMaxFields = {
  __typename: 'GeneratedImageMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  generationRequestId: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  imageId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GeneratedImageMinFields = {
  __typename: 'GeneratedImageMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  generationRequestId: Maybe<Scalars['uuid']>;
  id: Maybe<Scalars['uuid']>;
  imageId: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "generated_image" */
export type GeneratedImageMutationResponse = {
  __typename: 'GeneratedImageMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GeneratedImage>;
};

/** on_conflict condition type for table "generated_image" */
export type GeneratedImageOnConflict = {
  constraint: GeneratedImageConstraint;
  update_columns?: Array<GeneratedImageUpdateColumn>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};

/** Ordering options when selecting data from "generated_image". */
export type GeneratedImageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  generationRequest?: InputMaybe<GenerationRequestsOrderBy>;
  generationRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generated_image */
export type GeneratedImagePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "generated_image" */
export enum GeneratedImageSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GenerationRequestId = 'generationRequestId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId'
}

/** input type for updating data in table "generated_image" */
export type GeneratedImageSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generationRequestId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "generated_image" */
export type GeneratedImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GeneratedImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeneratedImageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generationRequestId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "generated_image" */
export enum GeneratedImageUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GenerationRequestId = 'generationRequestId',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId'
}

export type GeneratedImageUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeneratedImageSetInput>;
  /** filter the rows which have to be updated */
  where: GeneratedImageBoolExp;
};

/** columns and relationships of "image" */
export type Image = {
  __typename: 'Image';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  generatedImages: Array<GeneratedImage>;
  /** An aggregate relationship */
  generatedImagesAggregate: GeneratedImageAggregate;
  /** An array relationship */
  generationRequests: Array<GenerationRequests>;
  /** An aggregate relationship */
  generationRequestsAggregate: GenerationRequestsAggregate;
  id: Scalars['uuid'];
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt: Scalars['timestamptz'];
  /** A computed field, executes function "get_image_url" */
  url: Maybe<Scalars['String']>;
};


/** columns and relationships of "image" */
export type ImageGeneratedImagesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


/** columns and relationships of "image" */
export type ImageGeneratedImagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


/** columns and relationships of "image" */
export type ImageGenerationRequestsArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


/** columns and relationships of "image" */
export type ImageGenerationRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};

/** aggregated selection of "image" */
export type ImageAggregate = {
  __typename: 'ImageAggregate';
  aggregate: Maybe<ImageAggregateFields>;
  nodes: Array<Image>;
};

/** aggregate fields of "image" */
export type ImageAggregateFields = {
  __typename: 'ImageAggregateFields';
  count: Scalars['Int'];
  max: Maybe<ImageMaxFields>;
  min: Maybe<ImageMinFields>;
};


/** aggregate fields of "image" */
export type ImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "image". All fields are combined with a logical 'AND'. */
export type ImageBoolExp = {
  _and?: InputMaybe<Array<ImageBoolExp>>;
  _not?: InputMaybe<ImageBoolExp>;
  _or?: InputMaybe<Array<ImageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  generatedImages?: InputMaybe<GeneratedImageBoolExp>;
  generatedImages_aggregate?: InputMaybe<Generated_Image_Aggregate_Bool_Exp>;
  generationRequests?: InputMaybe<GenerationRequestsBoolExp>;
  generationRequests_aggregate?: InputMaybe<GenerationRequests_Aggregate_Bool_Exp>;
  id?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "image" */
export enum ImageConstraint {
  /** unique or primary key constraint on columns "id" */
  ImagePkey = 'image_pkey'
}

/** input type for inserting data into table "image" */
export type ImageInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generatedImages?: InputMaybe<GeneratedImageArrRelInsertInput>;
  generationRequests?: InputMaybe<GenerationRequestsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ImageMaxFields = {
  __typename: 'ImageMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ImageMinFields = {
  __typename: 'ImageMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "image" */
export type ImageMutationResponse = {
  __typename: 'ImageMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Image>;
};

/** input type for inserting object relation for remote table "image" */
export type ImageObjRelInsertInput = {
  data: ImageInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ImageOnConflict>;
};

/** on_conflict condition type for table "image" */
export type ImageOnConflict = {
  constraint: ImageConstraint;
  update_columns?: Array<ImageUpdateColumn>;
  where?: InputMaybe<ImageBoolExp>;
};

/** Ordering options when selecting data from "image". */
export type ImageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  generatedImagesAggregate?: InputMaybe<GeneratedImageAggregateOrderBy>;
  generationRequestsAggregate?: InputMaybe<GenerationRequestsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: image */
export type ImagePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "image" */
export enum ImageSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "image" */
export type ImageSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "image" */
export type ImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** This column is only needed because of this bug https://github.com/hasura/graphql-engine/issues/6026 */
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "image" */
export enum ImageUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ImageUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImageSetInput>;
  /** filter the rows which have to be updated */
  where: ImageBoolExp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type PresignedUrl = {
  __typename: 'PresignedUrl';
  expiresIn: Scalars['Int'];
  key: Scalars['String'];
  url: Scalars['String'];
};

/** columns and relationships of "project" */
export type Project = {
  __typename: 'Project';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  generationRequests: Array<GenerationRequests>;
  /** An aggregate relationship */
  generationRequestsAggregate: GenerationRequestsAggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};


/** columns and relationships of "project" */
export type ProjectGenerationRequestsArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


/** columns and relationships of "project" */
export type ProjectGenerationRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};

/** aggregated selection of "project" */
export type ProjectAggregate = {
  __typename: 'ProjectAggregate';
  aggregate: Maybe<ProjectAggregateFields>;
  nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type ProjectAggregateFields = {
  __typename: 'ProjectAggregateFields';
  count: Scalars['Int'];
  max: Maybe<ProjectMaxFields>;
  min: Maybe<ProjectMinFields>;
};


/** aggregate fields of "project" */
export type ProjectAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProjectSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project" */
export type ProjectAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project" */
export type ProjectArrRelInsertInput = {
  data: Array<ProjectInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectOnConflict>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type ProjectBoolExp = {
  _and?: InputMaybe<Array<ProjectBoolExp>>;
  _not?: InputMaybe<ProjectBoolExp>;
  _or?: InputMaybe<Array<ProjectBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  generationRequests?: InputMaybe<GenerationRequestsBoolExp>;
  generationRequests_aggregate?: InputMaybe<GenerationRequests_Aggregate_Bool_Exp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "project" */
export enum ProjectConstraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPkey = 'project_pkey'
}

/** input type for inserting data into table "project" */
export type ProjectInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generationRequests?: InputMaybe<GenerationRequestsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProjectMaxFields = {
  __typename: 'ProjectMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ProjectMinFields = {
  __typename: 'ProjectMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  name: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project" */
export type ProjectMutationResponse = {
  __typename: 'ProjectMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type ProjectObjRelInsertInput = {
  data: ProjectInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<ProjectOnConflict>;
};

/** on_conflict condition type for table "project" */
export type ProjectOnConflict = {
  constraint: ProjectConstraint;
  update_columns?: Array<ProjectUpdateColumn>;
  where?: InputMaybe<ProjectBoolExp>;
};

/** Ordering options when selecting data from "project". */
export type ProjectOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  generationRequestsAggregate?: InputMaybe<GenerationRequestsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: project */
export type ProjectPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "project" */
export enum ProjectSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "project" */
export type ProjectSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project" */
export type ProjectStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProjectStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProjectStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project" */
export enum ProjectUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId'
}

export type ProjectUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProjectSetInput>;
  /** filter the rows which have to be updated */
  where: ProjectBoolExp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "technical" */
export type Technical = {
  __typename: 'Technical';
  createdAt: Scalars['timestamptz'];
  expiresAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

/** aggregated selection of "technical" */
export type TechnicalAggregate = {
  __typename: 'TechnicalAggregate';
  aggregate: Maybe<TechnicalAggregateFields>;
  nodes: Array<Technical>;
};

/** aggregate fields of "technical" */
export type TechnicalAggregateFields = {
  __typename: 'TechnicalAggregateFields';
  count: Scalars['Int'];
  max: Maybe<TechnicalMaxFields>;
  min: Maybe<TechnicalMinFields>;
};


/** aggregate fields of "technical" */
export type TechnicalAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TechnicalSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "technical". All fields are combined with a logical 'AND'. */
export type TechnicalBoolExp = {
  _and?: InputMaybe<Array<TechnicalBoolExp>>;
  _not?: InputMaybe<TechnicalBoolExp>;
  _or?: InputMaybe<Array<TechnicalBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "technical" */
export enum TechnicalConstraint {
  /** unique or primary key constraint on columns "id" */
  TechnicalPkey = 'technical_pkey'
}

/** input type for inserting data into table "technical" */
export type TechnicalInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TechnicalMaxFields = {
  __typename: 'TechnicalMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  expiresAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type TechnicalMinFields = {
  __typename: 'TechnicalMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  expiresAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "technical" */
export type TechnicalMutationResponse = {
  __typename: 'TechnicalMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Technical>;
};

/** on_conflict condition type for table "technical" */
export type TechnicalOnConflict = {
  constraint: TechnicalConstraint;
  update_columns?: Array<TechnicalUpdateColumn>;
  where?: InputMaybe<TechnicalBoolExp>;
};

/** Ordering options when selecting data from "technical". */
export type TechnicalOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: technical */
export type TechnicalPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "technical" */
export enum TechnicalSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "technical" */
export type TechnicalSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "technical" */
export type TechnicalStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TechnicalStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TechnicalStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "technical" */
export enum TechnicalUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id'
}

export type TechnicalUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TechnicalSetInput>;
  /** filter the rows which have to be updated */
  where: TechnicalBoolExp;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AuthRefreshTokenArgs = {
  id?: InputMaybe<Scalars['uuid']>;
};

export type Auth_Confirmed_Session_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Confirmed_Session_Aggregate_Bool_Exp_Count>;
};

export type Auth_Confirmed_Session_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AuthConfirmedSessionBoolExp>;
  predicate: IntComparisonExp;
};

/** order by max() on columns of table "auth.confirmed_session" */
export type Auth_Confirmed_Session_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "auth.confirmed_session" */
export type Auth_Confirmed_Session_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export type Generated_Image_Aggregate_Bool_Exp = {
  count?: InputMaybe<Generated_Image_Aggregate_Bool_Exp_Count>;
};

export type Generated_Image_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GeneratedImageBoolExp>;
  predicate: IntComparisonExp;
};

/** order by max() on columns of table "generated_image" */
export type Generated_Image_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  generationRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "generated_image" */
export type Generated_Image_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  generationRequestId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "generation_request" */
export type GenerationRequests = {
  __typename: 'generationRequests';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  generatedImages: Array<GeneratedImage>;
  /** An aggregate relationship */
  generatedImagesAggregate: GeneratedImageAggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  image: Maybe<Image>;
  imageId: Scalars['uuid'];
  input: Scalars['jsonb'];
  /** An object relationship */
  mask: Maybe<Image>;
  maskId: Maybe<Scalars['uuid']>;
  /** An object relationship */
  project: Project;
  projectId: Scalars['uuid'];
};


/** columns and relationships of "generation_request" */
export type GenerationRequestsGeneratedImagesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


/** columns and relationships of "generation_request" */
export type GenerationRequestsGeneratedImagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


/** columns and relationships of "generation_request" */
export type GenerationRequestsInputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "generation_request" */
export type GenerationRequestsAggregate = {
  __typename: 'generationRequestsAggregate';
  aggregate: Maybe<GenerationRequestsAggregateFields>;
  nodes: Array<GenerationRequests>;
};

/** aggregate fields of "generation_request" */
export type GenerationRequestsAggregateFields = {
  __typename: 'generationRequestsAggregateFields';
  count: Scalars['Int'];
  max: Maybe<GenerationRequestsMaxFields>;
  min: Maybe<GenerationRequestsMinFields>;
};


/** aggregate fields of "generation_request" */
export type GenerationRequestsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "generation_request" */
export type GenerationRequestsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GenerationRequests_Max_Order_By>;
  min?: InputMaybe<GenerationRequests_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GenerationRequestsAppendInput = {
  input?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "generation_request" */
export type GenerationRequestsArrRelInsertInput = {
  data: Array<GenerationRequestsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<GenerationRequestsOnConflict>;
};

/** Boolean expression to filter rows from the table "generation_request". All fields are combined with a logical 'AND'. */
export type GenerationRequestsBoolExp = {
  _and?: InputMaybe<Array<GenerationRequestsBoolExp>>;
  _not?: InputMaybe<GenerationRequestsBoolExp>;
  _or?: InputMaybe<Array<GenerationRequestsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  generatedImages?: InputMaybe<GeneratedImageBoolExp>;
  generatedImages_aggregate?: InputMaybe<Generated_Image_Aggregate_Bool_Exp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<ImageBoolExp>;
  imageId?: InputMaybe<UuidComparisonExp>;
  input?: InputMaybe<JsonbComparisonExp>;
  mask?: InputMaybe<ImageBoolExp>;
  maskId?: InputMaybe<UuidComparisonExp>;
  project?: InputMaybe<ProjectBoolExp>;
  projectId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "generation_request" */
export enum GenerationRequestsConstraint {
  /** unique or primary key constraint on columns "id" */
  GenerationRequestPkey = 'generation_request_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GenerationRequestsDeleteAtPathInput = {
  input?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GenerationRequestsDeleteElemInput = {
  input?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GenerationRequestsDeleteKeyInput = {
  input?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "generation_request" */
export type GenerationRequestsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  generatedImages?: InputMaybe<GeneratedImageArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<ImageObjRelInsertInput>;
  imageId?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  mask?: InputMaybe<ImageObjRelInsertInput>;
  maskId?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<ProjectObjRelInsertInput>;
  projectId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GenerationRequestsMaxFields = {
  __typename: 'generationRequestsMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  imageId: Maybe<Scalars['uuid']>;
  maskId: Maybe<Scalars['uuid']>;
  projectId: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GenerationRequestsMinFields = {
  __typename: 'generationRequestsMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  imageId: Maybe<Scalars['uuid']>;
  maskId: Maybe<Scalars['uuid']>;
  projectId: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "generation_request" */
export type GenerationRequestsMutationResponse = {
  __typename: 'generationRequestsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GenerationRequests>;
};

/** input type for inserting object relation for remote table "generation_request" */
export type GenerationRequestsObjRelInsertInput = {
  data: GenerationRequestsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<GenerationRequestsOnConflict>;
};

/** on_conflict condition type for table "generation_request" */
export type GenerationRequestsOnConflict = {
  constraint: GenerationRequestsConstraint;
  update_columns?: Array<GenerationRequestsUpdateColumn>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};

/** Ordering options when selecting data from "generation_request". */
export type GenerationRequestsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  generatedImagesAggregate?: InputMaybe<GeneratedImageAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<ImageOrderBy>;
  imageId?: InputMaybe<OrderBy>;
  input?: InputMaybe<OrderBy>;
  mask?: InputMaybe<ImageOrderBy>;
  maskId?: InputMaybe<OrderBy>;
  project?: InputMaybe<ProjectOrderBy>;
  projectId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: generation_request */
export type GenerationRequestsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GenerationRequestsPrependInput = {
  input?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "generation_request" */
export enum GenerationRequestsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Input = 'input',
  /** column name */
  MaskId = 'maskId',
  /** column name */
  ProjectId = 'projectId'
}

/** input type for updating data in table "generation_request" */
export type GenerationRequestsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageId?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  maskId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "generationRequests" */
export type GenerationRequestsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GenerationRequestsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GenerationRequestsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageId?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  maskId?: InputMaybe<Scalars['uuid']>;
  projectId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "generation_request" */
export enum GenerationRequestsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'imageId',
  /** column name */
  Input = 'input',
  /** column name */
  MaskId = 'maskId',
  /** column name */
  ProjectId = 'projectId'
}

export type GenerationRequestsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GenerationRequestsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<GenerationRequestsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<GenerationRequestsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<GenerationRequestsDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GenerationRequestsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GenerationRequestsSetInput>;
  /** filter the rows which have to be updated */
  where: GenerationRequestsBoolExp;
};

export type GenerationRequests_Aggregate_Bool_Exp = {
  count?: InputMaybe<GenerationRequests_Aggregate_Bool_Exp_Count>;
};

export type GenerationRequests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GenerationRequestsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by max() on columns of table "generation_request" */
export type GenerationRequests_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
  maskId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "generation_request" */
export type GenerationRequests_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imageId?: InputMaybe<OrderBy>;
  maskId?: InputMaybe<OrderBy>;
  projectId?: InputMaybe<OrderBy>;
};

/** mutation root */
export type Mutation_Root = {
  __typename: 'mutation_root';
  /** execute VOLATILE function "auth.refresh_token" which returns "auth.confirmed_session" */
  authRefreshToken: Maybe<AuthConfirmedSession>;
  /** insert a single row into the table: "auth.email_confirmation" */
  confirmEmail: Maybe<AuthEmailConfirmation>;
  /** insert data into the table: "auth.email_confirmation" */
  confirmEmails: Maybe<AuthEmailConfirmationMutationResponse>;
  /** delete data from the table: "auth.email_confirmation" */
  deleteAuthEmailConfirmation: Maybe<AuthEmailConfirmationMutationResponse>;
  /** delete single row from the table: "auth.email_confirmation" */
  deleteAuthEmailConfirmationByPk: Maybe<AuthEmailConfirmation>;
  /** delete data from the table: "auth.email_confirmation_request" */
  deleteAuthEmailConfirmationRequest: Maybe<AuthEmailConfirmationRequestMutationResponse>;
  /** delete single row from the table: "auth.email_confirmation_request" */
  deleteAuthEmailConfirmationRequestByPk: Maybe<AuthEmailConfirmationRequest>;
  /** delete data from the table: "auth.logout" */
  deleteAuthLogout: Maybe<AuthLogoutMutationResponse>;
  /** delete single row from the table: "auth.logout" */
  deleteAuthLogoutByPk: Maybe<AuthLogout>;
  /** delete data from the table: "auth.token" */
  deleteAuthToken: Maybe<AuthTokenMutationResponse>;
  /** delete single row from the table: "auth.token" */
  deleteAuthTokenByPk: Maybe<AuthToken>;
  /** delete data from the table: "generated_image" */
  deleteGeneratedImage: Maybe<GeneratedImageMutationResponse>;
  /** delete single row from the table: "generated_image" */
  deleteGeneratedImageByPk: Maybe<GeneratedImage>;
  /** delete data from the table: "generation_request" */
  deleteGenerationRequests: Maybe<GenerationRequestsMutationResponse>;
  /** delete single row from the table: "generation_request" */
  deleteGenerationRequestsByPk: Maybe<GenerationRequests>;
  /** delete data from the table: "image" */
  deleteImage: Maybe<ImageMutationResponse>;
  /** delete single row from the table: "image" */
  deleteImageByPk: Maybe<Image>;
  /** delete data from the table: "project" */
  deleteProject: Maybe<ProjectMutationResponse>;
  /** delete single row from the table: "project" */
  deleteProjectByPk: Maybe<Project>;
  /** delete data from the table: "auth.session" */
  deleteSessions: Maybe<SessionsMutationResponse>;
  /** delete single row from the table: "auth.session" */
  deleteSessionsByPk: Maybe<Sessions>;
  /** delete data from the table: "technical" */
  deleteTechnical: Maybe<TechnicalMutationResponse>;
  /** delete single row from the table: "technical" */
  deleteTechnicalByPk: Maybe<Technical>;
  /** delete data from the table: "auth.user" */
  deleteUsers: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "auth.user" */
  deleteUsersByPk: Maybe<Users>;
  getUploadUrl: PresignedUrl;
  /** insert data into the table: "auth.logout" */
  insertAuthLogout: Maybe<AuthLogoutMutationResponse>;
  /** insert a single row into the table: "auth.logout" */
  insertAuthLogoutOne: Maybe<AuthLogout>;
  /** insert data into the table: "auth.token" */
  insertAuthToken: Maybe<AuthTokenMutationResponse>;
  /** insert a single row into the table: "auth.token" */
  insertAuthTokenOne: Maybe<AuthToken>;
  /** insert data into the table: "generated_image" */
  insertGeneratedImage: Maybe<GeneratedImageMutationResponse>;
  /** insert a single row into the table: "generated_image" */
  insertGeneratedImageOne: Maybe<GeneratedImage>;
  /** insert data into the table: "generation_request" */
  insertGenerationRequests: Maybe<GenerationRequestsMutationResponse>;
  /** insert a single row into the table: "generation_request" */
  insertGenerationRequestsOne: Maybe<GenerationRequests>;
  /** insert data into the table: "image" */
  insertImage: Maybe<ImageMutationResponse>;
  /** insert a single row into the table: "image" */
  insertImageOne: Maybe<Image>;
  /** insert data into the table: "project" */
  insertProject: Maybe<ProjectMutationResponse>;
  /** insert a single row into the table: "project" */
  insertProjectOne: Maybe<Project>;
  /** insert data into the table: "auth.session" */
  insertSessions: Maybe<SessionsMutationResponse>;
  /** insert a single row into the table: "auth.session" */
  insertSessionsOne: Maybe<Sessions>;
  /** insert data into the table: "technical" */
  insertTechnical: Maybe<TechnicalMutationResponse>;
  /** insert a single row into the table: "technical" */
  insertTechnicalOne: Maybe<Technical>;
  /** insert data into the table: "auth.user" */
  insertUsers: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "auth.user" */
  insertUsersOne: Maybe<Users>;
  /** insert a single row into the table: "auth.email_confirmation_request" */
  requestTokenByEmail: Maybe<AuthEmailConfirmationRequest>;
  /** insert data into the table: "auth.email_confirmation_request" */
  requestTokenByEmails: Maybe<AuthEmailConfirmationRequestMutationResponse>;
  /** update data of the table: "auth.email_confirmation" */
  updateAuthEmailConfirmation: Maybe<AuthEmailConfirmationMutationResponse>;
  /** update single row of the table: "auth.email_confirmation" */
  updateAuthEmailConfirmationByPk: Maybe<AuthEmailConfirmation>;
  /** update multiples rows of table: "auth.email_confirmation" */
  updateAuthEmailConfirmationMany: Maybe<Array<Maybe<AuthEmailConfirmationMutationResponse>>>;
  /** update data of the table: "auth.email_confirmation_request" */
  updateAuthEmailConfirmationRequest: Maybe<AuthEmailConfirmationRequestMutationResponse>;
  /** update single row of the table: "auth.email_confirmation_request" */
  updateAuthEmailConfirmationRequestByPk: Maybe<AuthEmailConfirmationRequest>;
  /** update multiples rows of table: "auth.email_confirmation_request" */
  updateAuthEmailConfirmationRequestMany: Maybe<Array<Maybe<AuthEmailConfirmationRequestMutationResponse>>>;
  /** update data of the table: "auth.logout" */
  updateAuthLogout: Maybe<AuthLogoutMutationResponse>;
  /** update single row of the table: "auth.logout" */
  updateAuthLogoutByPk: Maybe<AuthLogout>;
  /** update multiples rows of table: "auth.logout" */
  updateAuthLogoutMany: Maybe<Array<Maybe<AuthLogoutMutationResponse>>>;
  /** update data of the table: "auth.token" */
  updateAuthToken: Maybe<AuthTokenMutationResponse>;
  /** update single row of the table: "auth.token" */
  updateAuthTokenByPk: Maybe<AuthToken>;
  /** update multiples rows of table: "auth.token" */
  updateAuthTokenMany: Maybe<Array<Maybe<AuthTokenMutationResponse>>>;
  /** update data of the table: "generated_image" */
  updateGeneratedImage: Maybe<GeneratedImageMutationResponse>;
  /** update single row of the table: "generated_image" */
  updateGeneratedImageByPk: Maybe<GeneratedImage>;
  /** update multiples rows of table: "generated_image" */
  updateGeneratedImageMany: Maybe<Array<Maybe<GeneratedImageMutationResponse>>>;
  /** update data of the table: "generation_request" */
  updateGenerationRequests: Maybe<GenerationRequestsMutationResponse>;
  /** update single row of the table: "generation_request" */
  updateGenerationRequestsByPk: Maybe<GenerationRequests>;
  /** update multiples rows of table: "generation_request" */
  updateGenerationRequestsMany: Maybe<Array<Maybe<GenerationRequestsMutationResponse>>>;
  /** update data of the table: "image" */
  updateImage: Maybe<ImageMutationResponse>;
  /** update single row of the table: "image" */
  updateImageByPk: Maybe<Image>;
  /** update multiples rows of table: "image" */
  updateImageMany: Maybe<Array<Maybe<ImageMutationResponse>>>;
  /** update data of the table: "project" */
  updateProject: Maybe<ProjectMutationResponse>;
  /** update single row of the table: "project" */
  updateProjectByPk: Maybe<Project>;
  /** update multiples rows of table: "project" */
  updateProjectMany: Maybe<Array<Maybe<ProjectMutationResponse>>>;
  /** update data of the table: "auth.session" */
  updateSessions: Maybe<SessionsMutationResponse>;
  /** update single row of the table: "auth.session" */
  updateSessionsByPk: Maybe<Sessions>;
  /** update multiples rows of table: "auth.session" */
  updateSessionsMany: Maybe<Array<Maybe<SessionsMutationResponse>>>;
  /** update data of the table: "technical" */
  updateTechnical: Maybe<TechnicalMutationResponse>;
  /** update single row of the table: "technical" */
  updateTechnicalByPk: Maybe<Technical>;
  /** update multiples rows of table: "technical" */
  updateTechnicalMany: Maybe<Array<Maybe<TechnicalMutationResponse>>>;
  /** update data of the table: "auth.user" */
  updateUsers: Maybe<UsersMutationResponse>;
  /** update single row of the table: "auth.user" */
  updateUsersByPk: Maybe<Users>;
  /** update multiples rows of table: "auth.user" */
  updateUsersMany: Maybe<Array<Maybe<UsersMutationResponse>>>;
};


/** mutation root */
export type Mutation_RootAuthRefreshTokenArgs = {
  args: AuthRefreshTokenArgs;
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


/** mutation root */
export type Mutation_RootConfirmEmailArgs = {
  object: AuthEmailConfirmationInsertInput;
  onConflict?: InputMaybe<AuthEmailConfirmationOnConflict>;
};


/** mutation root */
export type Mutation_RootConfirmEmailsArgs = {
  objects: Array<AuthEmailConfirmationInsertInput>;
  onConflict?: InputMaybe<AuthEmailConfirmationOnConflict>;
};


/** mutation root */
export type Mutation_RootDeleteAuthEmailConfirmationArgs = {
  where: AuthEmailConfirmationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthEmailConfirmationByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteAuthEmailConfirmationRequestArgs = {
  where: AuthEmailConfirmationRequestBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthEmailConfirmationRequestByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutArgs = {
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthLogoutByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteAuthTokenArgs = {
  where: AuthTokenBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAuthTokenByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteGeneratedImageArgs = {
  where: GeneratedImageBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteGeneratedImageByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteGenerationRequestsArgs = {
  where: GenerationRequestsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteGenerationRequestsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteImageArgs = {
  where: ImageBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteImageByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteProjectArgs = {
  where: ProjectBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProjectByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteSessionsArgs = {
  where: SessionsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSessionsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteTechnicalArgs = {
  where: TechnicalBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTechnicalByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsertAuthLogoutArgs = {
  objects: Array<AuthLogoutInsertInput>;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthLogoutOneArgs = {
  object: AuthLogoutInsertInput;
  onConflict?: InputMaybe<AuthLogoutOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTokenArgs = {
  objects: Array<AuthTokenInsertInput>;
  onConflict?: InputMaybe<AuthTokenOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAuthTokenOneArgs = {
  object: AuthTokenInsertInput;
  onConflict?: InputMaybe<AuthTokenOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedImageArgs = {
  objects: Array<GeneratedImageInsertInput>;
  onConflict?: InputMaybe<GeneratedImageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGeneratedImageOneArgs = {
  object: GeneratedImageInsertInput;
  onConflict?: InputMaybe<GeneratedImageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGenerationRequestsArgs = {
  objects: Array<GenerationRequestsInsertInput>;
  onConflict?: InputMaybe<GenerationRequestsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGenerationRequestsOneArgs = {
  object: GenerationRequestsInsertInput;
  onConflict?: InputMaybe<GenerationRequestsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertImageArgs = {
  objects: Array<ImageInsertInput>;
  onConflict?: InputMaybe<ImageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertImageOneArgs = {
  object: ImageInsertInput;
  onConflict?: InputMaybe<ImageOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProjectArgs = {
  objects: Array<ProjectInsertInput>;
  onConflict?: InputMaybe<ProjectOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProjectOneArgs = {
  object: ProjectInsertInput;
  onConflict?: InputMaybe<ProjectOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSessionsArgs = {
  objects: Array<SessionsInsertInput>;
  onConflict?: InputMaybe<SessionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSessionsOneArgs = {
  object: SessionsInsertInput;
  onConflict?: InputMaybe<SessionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTechnicalArgs = {
  objects: Array<TechnicalInsertInput>;
  onConflict?: InputMaybe<TechnicalOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTechnicalOneArgs = {
  object: TechnicalInsertInput;
  onConflict?: InputMaybe<TechnicalOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  onConflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUsersOneArgs = {
  object: UsersInsertInput;
  onConflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type Mutation_RootRequestTokenByEmailArgs = {
  object: AuthEmailConfirmationRequestInsertInput;
  onConflict?: InputMaybe<AuthEmailConfirmationRequestOnConflict>;
};


/** mutation root */
export type Mutation_RootRequestTokenByEmailsArgs = {
  objects: Array<AuthEmailConfirmationRequestInsertInput>;
  onConflict?: InputMaybe<AuthEmailConfirmationRequestOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationArgs = {
  _set?: InputMaybe<AuthEmailConfirmationSetInput>;
  where: AuthEmailConfirmationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationByPkArgs = {
  _set?: InputMaybe<AuthEmailConfirmationSetInput>;
  pk_columns: AuthEmailConfirmationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationManyArgs = {
  updates: Array<AuthEmailConfirmationUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationRequestArgs = {
  _set?: InputMaybe<AuthEmailConfirmationRequestSetInput>;
  where: AuthEmailConfirmationRequestBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationRequestByPkArgs = {
  _set?: InputMaybe<AuthEmailConfirmationRequestSetInput>;
  pk_columns: AuthEmailConfirmationRequestPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthEmailConfirmationRequestManyArgs = {
  updates: Array<AuthEmailConfirmationRequestUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  where: AuthLogoutBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutByPkArgs = {
  _set?: InputMaybe<AuthLogoutSetInput>;
  pk_columns: AuthLogoutPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthLogoutManyArgs = {
  updates: Array<AuthLogoutUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateAuthTokenArgs = {
  _set?: InputMaybe<AuthTokenSetInput>;
  where: AuthTokenBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAuthTokenByPkArgs = {
  _set?: InputMaybe<AuthTokenSetInput>;
  pk_columns: AuthTokenPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAuthTokenManyArgs = {
  updates: Array<AuthTokenUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedImageArgs = {
  _set?: InputMaybe<GeneratedImageSetInput>;
  where: GeneratedImageBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedImageByPkArgs = {
  _set?: InputMaybe<GeneratedImageSetInput>;
  pk_columns: GeneratedImagePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateGeneratedImageManyArgs = {
  updates: Array<GeneratedImageUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateGenerationRequestsArgs = {
  _append?: InputMaybe<GenerationRequestsAppendInput>;
  _deleteAtPath?: InputMaybe<GenerationRequestsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<GenerationRequestsDeleteElemInput>;
  _deleteKey?: InputMaybe<GenerationRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<GenerationRequestsPrependInput>;
  _set?: InputMaybe<GenerationRequestsSetInput>;
  where: GenerationRequestsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateGenerationRequestsByPkArgs = {
  _append?: InputMaybe<GenerationRequestsAppendInput>;
  _deleteAtPath?: InputMaybe<GenerationRequestsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<GenerationRequestsDeleteElemInput>;
  _deleteKey?: InputMaybe<GenerationRequestsDeleteKeyInput>;
  _prepend?: InputMaybe<GenerationRequestsPrependInput>;
  _set?: InputMaybe<GenerationRequestsSetInput>;
  pk_columns: GenerationRequestsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateGenerationRequestsManyArgs = {
  updates: Array<GenerationRequestsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateImageArgs = {
  _set?: InputMaybe<ImageSetInput>;
  where: ImageBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateImageByPkArgs = {
  _set?: InputMaybe<ImageSetInput>;
  pk_columns: ImagePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateImageManyArgs = {
  updates: Array<ImageUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateProjectArgs = {
  _set?: InputMaybe<ProjectSetInput>;
  where: ProjectBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProjectByPkArgs = {
  _set?: InputMaybe<ProjectSetInput>;
  pk_columns: ProjectPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProjectManyArgs = {
  updates: Array<ProjectUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateSessionsArgs = {
  _set?: InputMaybe<SessionsSetInput>;
  where: SessionsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSessionsByPkArgs = {
  _set?: InputMaybe<SessionsSetInput>;
  pk_columns: SessionsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSessionsManyArgs = {
  updates: Array<SessionsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateTechnicalArgs = {
  _set?: InputMaybe<TechnicalSetInput>;
  where: TechnicalBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTechnicalByPkArgs = {
  _set?: InputMaybe<TechnicalSetInput>;
  pk_columns: TechnicalPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTechnicalManyArgs = {
  updates: Array<TechnicalUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

export type Project_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Aggregate_Bool_Exp_Count>;
};

export type Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProjectSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProjectBoolExp>;
  predicate: IntComparisonExp;
};

/** order by max() on columns of table "project" */
export type Project_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "project" */
export type Project_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

export type Query_Root = {
  __typename: 'query_root';
  /** fetch data from the table: "auth.active_session" */
  authActiveSession: Array<AuthActiveSession>;
  /** fetch aggregated fields from the table: "auth.active_session" */
  authActiveSessionAggregate: AuthActiveSessionAggregate;
  /** fetch data from the table: "auth.confirmed_session" */
  authConfirmedSession: Array<AuthConfirmedSession>;
  /** fetch aggregated fields from the table: "auth.confirmed_session" */
  authConfirmedSessionAggregate: AuthConfirmedSessionAggregate;
  /** execute function "auth.current_session" and query aggregates on result of table type "auth.active_session" */
  authCurrentSessionAggregate: AuthActiveSessionAggregate;
  /** fetch aggregated fields from the table: "auth.email_confirmation" */
  authEmailConfirmationAggregate: AuthEmailConfirmationAggregate;
  /** fetch data from the table: "auth.email_confirmation_request" */
  authEmailConfirmationRequest: Array<AuthEmailConfirmationRequest>;
  /** fetch aggregated fields from the table: "auth.email_confirmation_request" */
  authEmailConfirmationRequestAggregate: AuthEmailConfirmationRequestAggregate;
  /** fetch data from the table: "auth.email_confirmation_request" using primary key columns */
  authEmailConfirmationRequestByPk: Maybe<AuthEmailConfirmationRequest>;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk: Maybe<AuthLogout>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  authMeAggregate: UsersAggregate;
  /** fetch data from the table: "auth.token" */
  authToken: Array<AuthToken>;
  /** fetch aggregated fields from the table: "auth.token" */
  authTokenAggregate: AuthTokenAggregate;
  /** fetch data from the table: "auth.token" using primary key columns */
  authTokenByPk: Maybe<AuthToken>;
  /** fetch data from the table: "auth.email_confirmation" using primary key columns */
  confirmedEmail: Maybe<AuthEmailConfirmation>;
  /** fetch data from the table: "auth.email_confirmation" */
  confirmedEmails: Array<AuthEmailConfirmation>;
  /** execute function "auth.current_session" which returns "auth.active_session" */
  currentSession: Maybe<AuthActiveSession>;
  /** fetch data from the table: "generated_image" using primary key columns */
  generatedImage: Maybe<GeneratedImage>;
  /** fetch aggregated fields from the table: "generated_image" */
  generatedImageAggregate: GeneratedImageAggregate;
  /** An array relationship */
  generatedImages: Array<GeneratedImage>;
  /** fetch data from the table: "generation_request" using primary key columns */
  generationRequest: Maybe<GenerationRequests>;
  /** An array relationship */
  generationRequests: Array<GenerationRequests>;
  /** An aggregate relationship */
  generationRequestsAggregate: GenerationRequestsAggregate;
  /** fetch data from the table: "image" */
  image: Array<Image>;
  /** fetch aggregated fields from the table: "image" */
  imageAggregate: ImageAggregate;
  /** fetch data from the table: "image" using primary key columns */
  imageByPk: Maybe<Image>;
  /** execute function "auth.me" which returns "auth.user" */
  me: Maybe<Users>;
  /** fetch data from the table: "project" using primary key columns */
  project: Maybe<Project>;
  /** fetch aggregated fields from the table: "project" */
  projectAggregate: ProjectAggregate;
  /** An array relationship */
  projects: Array<Project>;
  /** fetch data from the table: "auth.session" using primary key columns */
  session: Maybe<Sessions>;
  /** fetch data from the table: "auth.session" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "auth.session" */
  sessionsAggregate: SessionsAggregate;
  /** fetch data from the table: "technical" */
  technical: Array<Technical>;
  /** fetch aggregated fields from the table: "technical" */
  technicalAggregate: TechnicalAggregate;
  /** fetch data from the table: "technical" using primary key columns */
  technicalByPk: Maybe<Technical>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user: Maybe<Users>;
  /** fetch data from the table: "auth.user" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.user" */
  usersAggregate: UsersAggregate;
};


export type Query_RootAuthActiveSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Query_RootAuthActiveSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Query_RootAuthConfirmedSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


export type Query_RootAuthConfirmedSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


export type Query_RootAuthCurrentSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Query_RootAuthEmailConfirmationAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};


export type Query_RootAuthEmailConfirmationRequestArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationRequestOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};


export type Query_RootAuthEmailConfirmationRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationRequestOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};


export type Query_RootAuthEmailConfirmationRequestByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Query_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootAuthTokenArgs = {
  distinctOn?: InputMaybe<Array<AuthTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthTokenOrderBy>>;
  where?: InputMaybe<AuthTokenBoolExp>;
};


export type Query_RootAuthTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthTokenOrderBy>>;
  where?: InputMaybe<AuthTokenBoolExp>;
};


export type Query_RootAuthTokenByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConfirmedEmailArgs = {
  id: Scalars['uuid'];
};


export type Query_RootConfirmedEmailsArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};


export type Query_RootCurrentSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Query_RootGeneratedImageArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGeneratedImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


export type Query_RootGeneratedImagesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


export type Query_RootGenerationRequestArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGenerationRequestsArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


export type Query_RootGenerationRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


export type Query_RootImageArgs = {
  distinctOn?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type Query_RootImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type Query_RootImageByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMeArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootProjectArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProjectAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};


export type Query_RootProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};


export type Query_RootSessionArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSessionsArgs = {
  distinctOn?: InputMaybe<Array<SessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
  where?: InputMaybe<SessionsBoolExp>;
};


export type Query_RootSessionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
  where?: InputMaybe<SessionsBoolExp>;
};


export type Query_RootTechnicalArgs = {
  distinctOn?: InputMaybe<Array<TechnicalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TechnicalOrderBy>>;
  where?: InputMaybe<TechnicalBoolExp>;
};


export type Query_RootTechnicalAggregateArgs = {
  distinctOn?: InputMaybe<Array<TechnicalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TechnicalOrderBy>>;
  where?: InputMaybe<TechnicalBoolExp>;
};


export type Query_RootTechnicalByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Query_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** columns and relationships of "auth.session" */
export type Sessions = {
  __typename: 'sessions';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  logout: Maybe<AuthLogout>;
};

/** aggregated selection of "auth.session" */
export type SessionsAggregate = {
  __typename: 'sessionsAggregate';
  aggregate: Maybe<SessionsAggregateFields>;
  nodes: Array<Sessions>;
};

/** aggregate fields of "auth.session" */
export type SessionsAggregateFields = {
  __typename: 'sessionsAggregateFields';
  count: Scalars['Int'];
  max: Maybe<SessionsMaxFields>;
  min: Maybe<SessionsMinFields>;
};


/** aggregate fields of "auth.session" */
export type SessionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SessionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.session". All fields are combined with a logical 'AND'. */
export type SessionsBoolExp = {
  _and?: InputMaybe<Array<SessionsBoolExp>>;
  _not?: InputMaybe<SessionsBoolExp>;
  _or?: InputMaybe<Array<SessionsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  logout?: InputMaybe<AuthLogoutBoolExp>;
};

/** unique or primary key constraints on table "auth.session" */
export enum SessionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TokenPkey = 'token_pkey'
}

/** input type for inserting data into table "auth.session" */
export type SessionsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  logout?: InputMaybe<AuthLogoutObjRelInsertInput>;
};

/** aggregate max on columns */
export type SessionsMaxFields = {
  __typename: 'sessionsMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type SessionsMinFields = {
  __typename: 'sessionsMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.session" */
export type SessionsMutationResponse = {
  __typename: 'sessionsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sessions>;
};

/** on_conflict condition type for table "auth.session" */
export type SessionsOnConflict = {
  constraint: SessionsConstraint;
  update_columns?: Array<SessionsUpdateColumn>;
  where?: InputMaybe<SessionsBoolExp>;
};

/** Ordering options when selecting data from "auth.session". */
export type SessionsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  logout?: InputMaybe<AuthLogoutOrderBy>;
};

/** primary key columns input for table: auth.session */
export type SessionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.session" */
export enum SessionsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.session" */
export type SessionsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "sessions" */
export type SessionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SessionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SessionsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.session" */
export enum SessionsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id'
}

export type SessionsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SessionsSetInput>;
  /** filter the rows which have to be updated */
  where: SessionsBoolExp;
};

export type Subscription_Root = {
  __typename: 'subscription_root';
  /** fetch data from the table: "auth.active_session" */
  authActiveSession: Array<AuthActiveSession>;
  /** fetch aggregated fields from the table: "auth.active_session" */
  authActiveSessionAggregate: AuthActiveSessionAggregate;
  /** fetch data from the table in a streaming manner: "auth.active_session" */
  authActiveSessionStream: Array<AuthActiveSession>;
  /** fetch data from the table: "auth.confirmed_session" */
  authConfirmedSession: Array<AuthConfirmedSession>;
  /** fetch aggregated fields from the table: "auth.confirmed_session" */
  authConfirmedSessionAggregate: AuthConfirmedSessionAggregate;
  /** fetch data from the table in a streaming manner: "auth.confirmed_session" */
  authConfirmedSessionStream: Array<AuthConfirmedSession>;
  /** execute function "auth.current_session" and query aggregates on result of table type "auth.active_session" */
  authCurrentSessionAggregate: AuthActiveSessionAggregate;
  /** fetch aggregated fields from the table: "auth.email_confirmation" */
  authEmailConfirmationAggregate: AuthEmailConfirmationAggregate;
  /** fetch data from the table: "auth.email_confirmation_request" */
  authEmailConfirmationRequest: Array<AuthEmailConfirmationRequest>;
  /** fetch aggregated fields from the table: "auth.email_confirmation_request" */
  authEmailConfirmationRequestAggregate: AuthEmailConfirmationRequestAggregate;
  /** fetch data from the table: "auth.email_confirmation_request" using primary key columns */
  authEmailConfirmationRequestByPk: Maybe<AuthEmailConfirmationRequest>;
  /** fetch data from the table in a streaming manner: "auth.email_confirmation_request" */
  authEmailConfirmationRequestStream: Array<AuthEmailConfirmationRequest>;
  /** fetch data from the table in a streaming manner: "auth.email_confirmation" */
  authEmailConfirmationStream: Array<AuthEmailConfirmation>;
  /** fetch data from the table: "auth.logout" */
  authLogout: Array<AuthLogout>;
  /** fetch aggregated fields from the table: "auth.logout" */
  authLogoutAggregate: AuthLogoutAggregate;
  /** fetch data from the table: "auth.logout" using primary key columns */
  authLogoutByPk: Maybe<AuthLogout>;
  /** fetch data from the table in a streaming manner: "auth.logout" */
  authLogoutStream: Array<AuthLogout>;
  /** execute function "auth.me" and query aggregates on result of table type "auth.user" */
  authMeAggregate: UsersAggregate;
  /** fetch data from the table: "auth.token" */
  authToken: Array<AuthToken>;
  /** fetch aggregated fields from the table: "auth.token" */
  authTokenAggregate: AuthTokenAggregate;
  /** fetch data from the table: "auth.token" using primary key columns */
  authTokenByPk: Maybe<AuthToken>;
  /** fetch data from the table in a streaming manner: "auth.token" */
  authTokenStream: Array<AuthToken>;
  /** fetch data from the table: "auth.email_confirmation" using primary key columns */
  confirmedEmail: Maybe<AuthEmailConfirmation>;
  /** fetch data from the table: "auth.email_confirmation" */
  confirmedEmails: Array<AuthEmailConfirmation>;
  /** execute function "auth.current_session" which returns "auth.active_session" */
  currentSession: Maybe<AuthActiveSession>;
  /** fetch data from the table: "generated_image" using primary key columns */
  generatedImage: Maybe<GeneratedImage>;
  /** fetch aggregated fields from the table: "generated_image" */
  generatedImageAggregate: GeneratedImageAggregate;
  /** fetch data from the table in a streaming manner: "generated_image" */
  generatedImageStream: Array<GeneratedImage>;
  /** An array relationship */
  generatedImages: Array<GeneratedImage>;
  /** fetch data from the table: "generation_request" using primary key columns */
  generationRequest: Maybe<GenerationRequests>;
  /** An array relationship */
  generationRequests: Array<GenerationRequests>;
  /** An aggregate relationship */
  generationRequestsAggregate: GenerationRequestsAggregate;
  /** fetch data from the table in a streaming manner: "generation_request" */
  generationRequestsStream: Array<GenerationRequests>;
  /** fetch data from the table: "image" */
  image: Array<Image>;
  /** fetch aggregated fields from the table: "image" */
  imageAggregate: ImageAggregate;
  /** fetch data from the table: "image" using primary key columns */
  imageByPk: Maybe<Image>;
  /** fetch data from the table in a streaming manner: "image" */
  imageStream: Array<Image>;
  /** execute function "auth.me" which returns "auth.user" */
  me: Maybe<Users>;
  /** fetch data from the table: "project" using primary key columns */
  project: Maybe<Project>;
  /** fetch aggregated fields from the table: "project" */
  projectAggregate: ProjectAggregate;
  /** fetch data from the table in a streaming manner: "project" */
  projectStream: Array<Project>;
  /** An array relationship */
  projects: Array<Project>;
  /** fetch data from the table: "auth.session" using primary key columns */
  session: Maybe<Sessions>;
  /** fetch data from the table: "auth.session" */
  sessions: Array<Sessions>;
  /** fetch aggregated fields from the table: "auth.session" */
  sessionsAggregate: SessionsAggregate;
  /** fetch data from the table in a streaming manner: "auth.session" */
  sessionsStream: Array<Sessions>;
  /** fetch data from the table: "technical" */
  technical: Array<Technical>;
  /** fetch aggregated fields from the table: "technical" */
  technicalAggregate: TechnicalAggregate;
  /** fetch data from the table: "technical" using primary key columns */
  technicalByPk: Maybe<Technical>;
  /** fetch data from the table in a streaming manner: "technical" */
  technicalStream: Array<Technical>;
  /** fetch data from the table: "auth.user" using primary key columns */
  user: Maybe<Users>;
  /** fetch data from the table: "auth.user" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.user" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table in a streaming manner: "auth.user" */
  usersStream: Array<Users>;
};


export type Subscription_RootAuthActiveSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Subscription_RootAuthActiveSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Subscription_RootAuthActiveSessionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthActiveSessionStreamCursorInput>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Subscription_RootAuthConfirmedSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


export type Subscription_RootAuthConfirmedSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


export type Subscription_RootAuthConfirmedSessionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthConfirmedSessionStreamCursorInput>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


export type Subscription_RootAuthCurrentSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Subscription_RootAuthEmailConfirmationAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};


export type Subscription_RootAuthEmailConfirmationRequestArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationRequestOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};


export type Subscription_RootAuthEmailConfirmationRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationRequestOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};


export type Subscription_RootAuthEmailConfirmationRequestByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuthEmailConfirmationRequestStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthEmailConfirmationRequestStreamCursorInput>>;
  where?: InputMaybe<AuthEmailConfirmationRequestBoolExp>;
};


export type Subscription_RootAuthEmailConfirmationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthEmailConfirmationStreamCursorInput>>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};


export type Subscription_RootAuthLogoutArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthLogoutSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthLogoutOrderBy>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthLogoutByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuthLogoutStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthLogoutStreamCursorInput>>;
  where?: InputMaybe<AuthLogoutBoolExp>;
};


export type Subscription_RootAuthMeAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootAuthTokenArgs = {
  distinctOn?: InputMaybe<Array<AuthTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthTokenOrderBy>>;
  where?: InputMaybe<AuthTokenBoolExp>;
};


export type Subscription_RootAuthTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthTokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthTokenOrderBy>>;
  where?: InputMaybe<AuthTokenBoolExp>;
};


export type Subscription_RootAuthTokenByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuthTokenStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AuthTokenStreamCursorInput>>;
  where?: InputMaybe<AuthTokenBoolExp>;
};


export type Subscription_RootConfirmedEmailArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootConfirmedEmailsArgs = {
  distinctOn?: InputMaybe<Array<AuthEmailConfirmationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthEmailConfirmationOrderBy>>;
  where?: InputMaybe<AuthEmailConfirmationBoolExp>;
};


export type Subscription_RootCurrentSessionArgs = {
  distinctOn?: InputMaybe<Array<AuthActiveSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthActiveSessionOrderBy>>;
  where?: InputMaybe<AuthActiveSessionBoolExp>;
};


export type Subscription_RootGeneratedImageArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGeneratedImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


export type Subscription_RootGeneratedImageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<GeneratedImageStreamCursorInput>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


export type Subscription_RootGeneratedImagesArgs = {
  distinctOn?: InputMaybe<Array<GeneratedImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GeneratedImageOrderBy>>;
  where?: InputMaybe<GeneratedImageBoolExp>;
};


export type Subscription_RootGenerationRequestArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGenerationRequestsArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


export type Subscription_RootGenerationRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GenerationRequestsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenerationRequestsOrderBy>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


export type Subscription_RootGenerationRequestsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<GenerationRequestsStreamCursorInput>>;
  where?: InputMaybe<GenerationRequestsBoolExp>;
};


export type Subscription_RootImageArgs = {
  distinctOn?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type Subscription_RootImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<ImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ImageOrderBy>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type Subscription_RootImageByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ImageStreamCursorInput>>;
  where?: InputMaybe<ImageBoolExp>;
};


export type Subscription_RootMeArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootProjectArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProjectAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};


export type Subscription_RootProjectStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProjectStreamCursorInput>>;
  where?: InputMaybe<ProjectBoolExp>;
};


export type Subscription_RootProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};


export type Subscription_RootSessionArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSessionsArgs = {
  distinctOn?: InputMaybe<Array<SessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
  where?: InputMaybe<SessionsBoolExp>;
};


export type Subscription_RootSessionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<SessionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
  where?: InputMaybe<SessionsBoolExp>;
};


export type Subscription_RootSessionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SessionsStreamCursorInput>>;
  where?: InputMaybe<SessionsBoolExp>;
};


export type Subscription_RootTechnicalArgs = {
  distinctOn?: InputMaybe<Array<TechnicalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TechnicalOrderBy>>;
  where?: InputMaybe<TechnicalBoolExp>;
};


export type Subscription_RootTechnicalAggregateArgs = {
  distinctOn?: InputMaybe<Array<TechnicalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TechnicalOrderBy>>;
  where?: InputMaybe<TechnicalBoolExp>;
};


export type Subscription_RootTechnicalByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTechnicalStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<TechnicalStreamCursorInput>>;
  where?: InputMaybe<TechnicalBoolExp>;
};


export type Subscription_RootUserArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

/** columns and relationships of "auth.user" */
export type Users = {
  __typename: 'users';
  /** An array relationship */
  confirmedSessions: Array<AuthConfirmedSession>;
  /** An aggregate relationship */
  confirmedSessionsAggregate: AuthConfirmedSessionAggregate;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  projects: Array<Project>;
  /** An aggregate relationship */
  projectsAggregate: ProjectAggregate;
};


/** columns and relationships of "auth.user" */
export type UsersConfirmedSessionsArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


/** columns and relationships of "auth.user" */
export type UsersConfirmedSessionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AuthConfirmedSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuthConfirmedSessionOrderBy>>;
  where?: InputMaybe<AuthConfirmedSessionBoolExp>;
};


/** columns and relationships of "auth.user" */
export type UsersProjectsArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};


/** columns and relationships of "auth.user" */
export type UsersProjectsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProjectSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy>>;
  where?: InputMaybe<ProjectBoolExp>;
};

export type UsersAggregate = {
  __typename: 'usersAggregate';
  aggregate: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "auth.user" */
export type UsersAggregateFields = {
  __typename: 'usersAggregateFields';
  count: Scalars['Int'];
  max: Maybe<UsersMaxFields>;
  min: Maybe<UsersMinFields>;
};


/** aggregate fields of "auth.user" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  confirmedSessions?: InputMaybe<AuthConfirmedSessionBoolExp>;
  confirmedSessions_aggregate?: InputMaybe<Auth_Confirmed_Session_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  projects?: InputMaybe<ProjectBoolExp>;
  projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.user" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "auth.user" */
export type UsersInsertInput = {
  confirmedSessions?: InputMaybe<AuthConfirmedSessionArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  projects?: InputMaybe<ProjectArrRelInsertInput>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename: 'usersMaxFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename: 'usersMinFields';
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "auth.user" */
export type UsersMutationResponse = {
  __typename: 'usersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "auth.user" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "auth.user" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "auth.user". */
export type UsersOrderBy = {
  confirmedSessionsAggregate?: InputMaybe<AuthConfirmedSessionAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  projectsAggregate?: InputMaybe<ProjectAggregateOrderBy>;
};

/** primary key columns input for table: auth.user */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.user" */
export enum UsersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "auth.user" */
export type UsersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.user" */
export enum UsersUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

export type AddGenerationRequestMutationVariables = Exact<{
  data: GenerationRequestsInsertInput;
}>;


export type AddGenerationRequestMutation = { __typename: 'mutation_root', insertGenerationRequestsOne: { __typename: 'generationRequests', id: any } | null };

export type AddProjectMutationVariables = Exact<{
  data: ProjectInsertInput;
}>;


export type AddProjectMutation = { __typename: 'mutation_root', insertProjectOne: { __typename: 'Project', id: any, name: string, generationRequests: Array<{ __typename: 'generationRequests', id: any, generatedImages: Array<{ __typename: 'GeneratedImage', id: any, image: { __typename: 'Image', id: any, url: string | null } }> }> } | null };

export type GeneratedImagesSubscriptionVariables = Exact<{
  projectId: Scalars['uuid'];
  from: Scalars['timestamptz'];
  batchSize: Scalars['Int'];
}>;


export type GeneratedImagesSubscription = { __typename: 'subscription_root', generatedImageStream: Array<{ __typename: 'GeneratedImage', id: any, createdAt: any, image: { __typename: 'Image', id: any, url: string | null } }> };

export type GetUploadUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type GetUploadUrlMutation = { __typename: 'mutation_root', getUploadUrl: { __typename: 'PresignedUrl', url: string, key: string, expiresIn: number } };

export type ProjectItemFragment = { __typename: 'Project', id: any, name: string, generationRequests: Array<{ __typename: 'generationRequests', id: any, generatedImages: Array<{ __typename: 'GeneratedImage', id: any, image: { __typename: 'Image', id: any, url: string | null } }> }> };

export type ResultImageFragment = { __typename: 'GeneratedImage', id: any, createdAt: any, image: { __typename: 'Image', id: any, url: string | null } };

export type LoginMutationVariables = Exact<{
  token: Scalars['uuid'];
}>;


export type LoginMutation = { __typename: 'mutation_root', confirmEmails: { __typename: 'AuthEmailConfirmationMutationResponse', affected_rows: number } | null };

export type LogoutMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type LogoutMutation = { __typename: 'mutation_root', insertAuthLogoutOne: { __typename: 'AuthLogout', id: any } | null };

export type SessionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SessionSubscription = { __typename: 'subscription_root', currentSession: { __typename: 'AuthActiveSession', id: any | null, token: any | null, confirmation: { __typename: 'AuthConfirmedSession', id: any | null, userId: any | null } | null } | null };

export type TechinicalSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TechinicalSubscription = { __typename: 'subscription_root', technical: Array<{ __typename: 'Technical', id: any, expiresAt: any }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'query_root', me: { __typename: 'users', id: any, email: string } | null };

export type ProjectQueryVariables = Exact<{
  id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type ProjectQuery = { __typename: 'query_root', project: { __typename: 'Project', id: any, name: string, latestGenerationRequest: Array<{ __typename: 'generationRequests', maskId: any | null, input: any, image: { __typename: 'Image', id: any, url: string | null } | null }> } | null, generatedImageAggregate: { __typename: 'GeneratedImageAggregate', aggregate: { __typename: 'GeneratedImageAggregateFields', count: number } | null }, generatedImages: Array<{ __typename: 'GeneratedImage', id: any, createdAt: any, image: { __typename: 'Image', id: any, url: string | null } }> };

export type ProjectsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ProjectsSubscription = { __typename: 'subscription_root', projects: Array<{ __typename: 'Project', id: any, name: string, generationRequests: Array<{ __typename: 'generationRequests', id: any, generatedImages: Array<{ __typename: 'GeneratedImage', id: any, image: { __typename: 'Image', id: any, url: string | null } }> }> }> };

export type RequestTokenMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestTokenMutation = { __typename: 'mutation_root', requestTokenByEmail: { __typename: 'AuthEmailConfirmationRequest', id: any } | null };

export const ProjectItemFragmentDoc = gql`
    fragment ProjectItem on Project {
  id
  name
  generationRequests(
    limit: 1
    orderBy: {createdAt: DESC}
    where: {generatedImages: {}}
  ) {
    id
    generatedImages(limit: 1, orderBy: {createdAt: DESC}) {
      id
      image {
        id
        url
      }
    }
  }
}
    `;
export const ResultImageFragmentDoc = gql`
    fragment ResultImage on GeneratedImage {
  id
  createdAt
  image {
    id
    url
  }
}
    `;
export const AddGenerationRequestDocument = gql`
    mutation AddGenerationRequest($data: generationRequestsInsertInput!) {
  insertGenerationRequestsOne(object: $data) {
    id
  }
}
    `;
export type AddGenerationRequestMutationFn = Apollo.MutationFunction<AddGenerationRequestMutation, AddGenerationRequestMutationVariables>;

/**
 * __useAddGenerationRequestMutation__
 *
 * To run a mutation, you first call `useAddGenerationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGenerationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGenerationRequestMutation, { data, loading, error }] = useAddGenerationRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddGenerationRequestMutation(baseOptions?: Apollo.MutationHookOptions<AddGenerationRequestMutation, AddGenerationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGenerationRequestMutation, AddGenerationRequestMutationVariables>(AddGenerationRequestDocument, options);
      }
export type AddGenerationRequestMutationHookResult = ReturnType<typeof useAddGenerationRequestMutation>;
export type AddGenerationRequestMutationResult = Apollo.MutationResult<AddGenerationRequestMutation>;
export type AddGenerationRequestMutationOptions = Apollo.BaseMutationOptions<AddGenerationRequestMutation, AddGenerationRequestMutationVariables>;
export const AddProjectDocument = gql`
    mutation AddProject($data: ProjectInsertInput!) {
  insertProjectOne(object: $data) {
    ...ProjectItem
  }
}
    ${ProjectItemFragmentDoc}`;
export type AddProjectMutationFn = Apollo.MutationFunction<AddProjectMutation, AddProjectMutationVariables>;

/**
 * __useAddProjectMutation__
 *
 * To run a mutation, you first call `useAddProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMutation, { data, loading, error }] = useAddProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectMutation, AddProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectMutation, AddProjectMutationVariables>(AddProjectDocument, options);
      }
export type AddProjectMutationHookResult = ReturnType<typeof useAddProjectMutation>;
export type AddProjectMutationResult = Apollo.MutationResult<AddProjectMutation>;
export type AddProjectMutationOptions = Apollo.BaseMutationOptions<AddProjectMutation, AddProjectMutationVariables>;
export const GeneratedImagesDocument = gql`
    subscription GeneratedImages($projectId: uuid!, $from: timestamptz!, $batchSize: Int!) {
  generatedImageStream(
    batchSize: $batchSize
    where: {generationRequest: {projectId: {_eq: $projectId}}}
    cursor: {initialValue: {createdAt: $from}}
  ) {
    ...ResultImage
  }
}
    ${ResultImageFragmentDoc}`;

/**
 * __useGeneratedImagesSubscription__
 *
 * To run a query within a React component, call `useGeneratedImagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedImagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedImagesSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      from: // value for 'from'
 *      batchSize: // value for 'batchSize'
 *   },
 * });
 */
export function useGeneratedImagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<GeneratedImagesSubscription, GeneratedImagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GeneratedImagesSubscription, GeneratedImagesSubscriptionVariables>(GeneratedImagesDocument, options);
      }
export type GeneratedImagesSubscriptionHookResult = ReturnType<typeof useGeneratedImagesSubscription>;
export type GeneratedImagesSubscriptionResult = Apollo.SubscriptionResult<GeneratedImagesSubscription>;
export const GetUploadUrlDocument = gql`
    mutation GetUploadUrl {
  getUploadUrl {
    url
    key
    expiresIn
  }
}
    `;
export type GetUploadUrlMutationFn = Apollo.MutationFunction<GetUploadUrlMutation, GetUploadUrlMutationVariables>;

/**
 * __useGetUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUploadUrlMutation, { data, loading, error }] = useGetUploadUrlMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetUploadUrlMutation, GetUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUploadUrlMutation, GetUploadUrlMutationVariables>(GetUploadUrlDocument, options);
      }
export type GetUploadUrlMutationHookResult = ReturnType<typeof useGetUploadUrlMutation>;
export type GetUploadUrlMutationResult = Apollo.MutationResult<GetUploadUrlMutation>;
export type GetUploadUrlMutationOptions = Apollo.BaseMutationOptions<GetUploadUrlMutation, GetUploadUrlMutationVariables>;
export const LoginDocument = gql`
    mutation Login($token: uuid!) {
  confirmEmails(objects: [{id: $token}]) {
    affected_rows
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout($id: uuid!) {
  insertAuthLogoutOne(object: {id: $id}) {
    id
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const SessionDocument = gql`
    subscription Session {
  currentSession {
    id
    token
    confirmation {
      id
      userId
    }
  }
}
    `;

/**
 * __useSessionSubscription__
 *
 * To run a query within a React component, call `useSessionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSessionSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SessionSubscription, SessionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SessionSubscription, SessionSubscriptionVariables>(SessionDocument, options);
      }
export type SessionSubscriptionHookResult = ReturnType<typeof useSessionSubscription>;
export type SessionSubscriptionResult = Apollo.SubscriptionResult<SessionSubscription>;
export const TechinicalDocument = gql`
    subscription Techinical {
  technical(where: {expiresAt: {_gt: "now()"}}) {
    id
    expiresAt
  }
}
    `;

/**
 * __useTechinicalSubscription__
 *
 * To run a query within a React component, call `useTechinicalSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTechinicalSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechinicalSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTechinicalSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TechinicalSubscription, TechinicalSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<TechinicalSubscription, TechinicalSubscriptionVariables>(TechinicalDocument, options);
      }
export type TechinicalSubscriptionHookResult = ReturnType<typeof useTechinicalSubscription>;
export type TechinicalSubscriptionResult = Apollo.SubscriptionResult<TechinicalSubscription>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ProjectDocument = gql`
    query Project($id: uuid!, $limit: Int = 20, $offset: Int = 0) {
  project(id: $id) {
    id
    name
    latestGenerationRequest: generationRequests(
      orderBy: {createdAt: DESC}
      limit: 1
    ) {
      image {
        id
        url
      }
      maskId
      input
    }
  }
  generatedImageAggregate(where: {generationRequest: {projectId: {_eq: $id}}}) {
    aggregate {
      count
    }
  }
  generatedImages(
    orderBy: {createdAt: DESC}
    where: {generationRequest: {projectId: {_eq: $id}}}
    limit: $limit
    offset: $offset
  ) {
    ...ResultImage
  }
}
    ${ResultImageFragmentDoc}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    subscription Projects {
  projects(
    orderBy: {generationRequestsAggregate: {max: {createdAt: DESC_NULLS_LAST}}}
  ) {
    id
    name
    ...ProjectItem
  }
}
    ${ProjectItemFragmentDoc}`;

/**
 * __useProjectsSubscription__
 *
 * To run a query within a React component, call `useProjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useProjectsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ProjectsSubscription, ProjectsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectsSubscription, ProjectsSubscriptionVariables>(ProjectsDocument, options);
      }
export type ProjectsSubscriptionHookResult = ReturnType<typeof useProjectsSubscription>;
export type ProjectsSubscriptionResult = Apollo.SubscriptionResult<ProjectsSubscription>;
export const RequestTokenDocument = gql`
    mutation RequestToken($email: String!) {
  requestTokenByEmail(object: {email: $email}) {
    id
  }
}
    `;
export type RequestTokenMutationFn = Apollo.MutationFunction<RequestTokenMutation, RequestTokenMutationVariables>;

/**
 * __useRequestTokenMutation__
 *
 * To run a mutation, you first call `useRequestTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTokenMutation, { data, loading, error }] = useRequestTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestTokenMutation(baseOptions?: Apollo.MutationHookOptions<RequestTokenMutation, RequestTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestTokenMutation, RequestTokenMutationVariables>(RequestTokenDocument, options);
      }
export type RequestTokenMutationHookResult = ReturnType<typeof useRequestTokenMutation>;
export type RequestTokenMutationResult = Apollo.MutationResult<RequestTokenMutation>;
export type RequestTokenMutationOptions = Apollo.BaseMutationOptions<RequestTokenMutation, RequestTokenMutationVariables>;