import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { CircularProgress, IconButton } from '@mui/material'
import { createElement as $, FC } from 'react'
import { TOKEN_KEY } from 'App'
import { useLogoutMutation, useSessionSubscription } from 'queries'

const Logout: FC = () => {
  const { data } = useSessionSubscription({ fetchPolicy: 'cache-only' })
  const [mutate, { loading }] = useLogoutMutation({ variables: { id: data?.currentSession?.id }})
  const onClick = async () => {
    await mutate()
    localStorage.removeItem(TOKEN_KEY)
    window.location.href = '/'
  }
  return $(IconButton, { onClick, disabled: loading },
    loading
      ? $(CircularProgress, { size: 24 })
      : $(ExitToAppIcon))
}

export default Logout