import { Box, Button, Collapse, Fade, InputBase, Paper, Skeleton } from '@mui/material'
import { createElement as $, FC, useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import { useRequestTokenMutation, useSessionSubscription } from 'queries'

const Login = () => {
  const [email, setEmail] = useState<string | null>(null)
  const [mutate, { data, loading, error }] = useRequestTokenMutation()
  const handleLogin = () => email && mutate({ variables: { email }})

  let Result
  if (data?.requestTokenByEmail?.id)
    Result = $(Success)
  else if (error)
    Result = $(ErrorMessage, { message: error.message })
  else if (loading)
    Result = $(Skeleton, {
      animation: 'wave',
      width: '100%',
      height: '40px',
      variant: 'rounded',
      sx: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    })
  else
    Result = $(LoginButton, { handleLogin })

  return $(Fade, {
    in: true,
    children: 
      $(Box, {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '15vh',
        },
        $(Paper, null,
          $(Box, { minWidth: '40ex' },
            $(Input, { disabled: loading, onChange: setEmail })),
          $(Collapse, {
            in: !!email,
            children: Result
          })))
      })
}

const LoginButton: FC<LoginButtonProps> = ({
  handleLogin
}) => {

  useEffect(() => {
    if (handleLogin) {
      const keyPressHandler = ({ key }: KeyboardEvent) =>
        key === 'Enter' && handleLogin()
      window.addEventListener('keypress', keyPressHandler)
      return () => window.removeEventListener('keypress', keyPressHandler)
    }
  }, [handleLogin])

  return $(Button, {
    fullWidth: true,
    variant: 'contained',
    onClick: handleLogin,
    disabled: !handleLogin,
    sx: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      height: 40
    },
    }, 'Authorize')
}

const Input: FC<InputProps> = ({ disabled, onChange }) => {
  const [email, setEmail] = useState('')
  const isValidEmail = isEmail(email)
  useEffect(() => {
    onChange(isValidEmail ? email : null)
  }, [onChange, isValidEmail, email])
  return $(InputBase, {
    disabled,
    placeholder: 'Input email',
    fullWidth: true,
    value: email,
    autoFocus: true,
    sx: { padding: '1rem 1.5rem' },
    onChange: (event) => setEmail(event.target.value)
  })
}

const Success = () => {
  const { data } = useSessionSubscription()
  const confirmationId = data?.currentSession?.confirmation?.id
  useEffect(() => {
    if (confirmationId)
      window.location.reload()
  }, [confirmationId])
  return $(Box, { paddingX : '1.5rem', paddingBottom: '1rem' },
    confirmationId
      ? 'Session confirmed, redirecting'
      : 'Confirm authentication in your mailbox')
}

const ErrorMessage: FC<{ message: string }> = ({ message }) =>
  $(Box, { paddingX : '1.5rem', paddingBottom: '1rem' },
    message)

type InputProps = { disabled: boolean, onChange: (email: string | null) => void }
type LoginButtonProps = { handleLogin?: () => void }

export default Login