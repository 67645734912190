import {
  ApolloClient
} from '@apollo/client'
import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client/cache'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { useMemo } from 'react'

const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Techinical: {
      fields: {
        expiresAt: {
          read: (value) => new Date(value)
        }
      }
    },
    PresignedUrl: {
      keyFields: ['key']
    },
    Query: {
      fields: {
        generatedImages: offsetLimitPagination(['where', 'orderBy'])
      }
    }
  }
}

const useClient = (url: string, tokenKey: string) => {
  const clientConfig = useMemo(() => ({
    url,
    lazy: false,
    connectionParams: () => {
      const token = localStorage.getItem(tokenKey)
      if (token)
        return { headers: { token } }
    }
  }), [url, tokenKey])
  const wsClient = useMemo(() => createClient(clientConfig), [clientConfig])
  const cache = useMemo(() => new InMemoryCache(cacheConfig), [])
  const link = useMemo(() => new GraphQLWsLink(wsClient), [wsClient])
  const client = useMemo(() => new ApolloClient({ link, cache }), [link, cache])
  return client
}

export default useClient