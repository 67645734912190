
import { Box, Paper, Skeleton, styled } from '@mui/material'
import { createElement as $, FC } from 'react'
import { WithFetchMore } from '.'
import DynamicScrollSensor from 'components/DynamicScrollSensor'
import { ProjectQuery } from 'queries'
import ResultImage from './ResultImage'

const Content: FC<Pick<ProjectQuery, 'generatedImages' | 'generatedImageAggregate'> & WithFetchMore> = ({
  generatedImageAggregate,
  generatedImages,
  fetchMore
}) => 
  $(GenerationGrid, null,
    generatedImages.map(ResultImage),
    $(DynamicScrollSensor, {
      count: generatedImageAggregate.aggregate?.count || 0,
      length: generatedImages.length,
      loading: false,
      fetchMore,
      loadingElement: (value, index) =>
        $(Paper, { key: index, sx: { overflow: 'hidden' }},
          $(Skeleton, { width: '100%', height: '100%', variant: 'rectangular' }),
           'loading'),
      }))

const GenerationGrid = styled(Box)({
  display: 'grid',
  flexWrap: 'wrap',
  marginLeft: '1rem',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem'
})

export default Content